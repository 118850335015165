/* included only when we don't want to use fonts from google server */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'),
  local('OpenSans-Light'),
  url(fonts/OpenSans-300.woff)
  format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'),
  local('OpenSans'),
  url(fonts/OpenSans-400.woff)
  format('woff');
}
