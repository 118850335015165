.ember-light-table-wrapper {
  width: 100%;
  overflow-x: scroll;
}

.ember-light-table {
  .lt-head table {
    margin-bottom: 0;
  }

  .lt-body-wrap {
    overflow-y: auto;
  }

  .lt-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .total-cell {
    text-align: right;
  }
}

.ace-datetimepicker {
  display: block;
  position: relative;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 285px;
  height: auto;
  margin-left: 33%;
  top: 32px !important;
  right: auto !important;
  bottom: auto !important;
  left: auto !important;

  @media (max-width: 1024px) {
    table {
      font-size: 0.75rem;
    }
  }
}

.tags-field input {
  padding-left: 10px !important
}

.form-group.has-error .tags {
    border-color: #f2a696;
    color: #D68273;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.ember-power-select-multiple-trigger {
  height: auto !important;
  min-height: 30px;
  max-height: 100px;
}

// Took from Bootstrap CSS 4, as fallback if app is using Bootstrap CSS 3
.input-group {
  display: flex;

  .form-control {
    flex: 1 1 auto;
  }

  .input-group-btn {
    padding: 0;
    border: unset;
    width: auto;
  }

  .input-group-text {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1.25rem;
    border: 1px solid #ced4da;
  }
}

.input-group.no-actions {
  display: block;
}

.input-group .ember-basic-dropdown {
  flex: 1 1 auto;
}
