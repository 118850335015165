/* skin 1 */
.skin-1 {
  background-color: #4A4F56;
}
.skin-1 .navbar {
  background: #2C6AA0;
}
.skin-1 .sidebar {
  background-color: #222A2D;
  border-right-width: 0;
}
.skin-1 .nav-list > li {
  border-color: #3F4E54;
}
.skin-1 .nav-list > li > a {
  background-color: #222A2D;
  color: #bac2c8;
}
.skin-1 .nav-list > li:hover > a {
  background-color: #414B51;
  color: #E1EAF1;
}
.skin-1 .nav-list > li.open > a,
.skin-1 .nav-list > li.open:hover > a {
  color: #85C0EC;
  background-color: #222A2D;
}
.skin-1 .nav-list > li.active > a,
.skin-1 .nav-list > li.active:hover > a {
  background-color: #141A1B;
  color: #7BB7E5;
}
.skin-1 .nav-list > li:hover:before {
  background-color: #629CC9;
}
.skin-1 .nav-list > li.active:before {
  display: block;
  background-color: #4d96c6;
}
.skin-1 .nav-list li.active > a:after {
  border-right-color: #FFF;
  border-width: 11px 7px;
  top: 8px;
  right: 0;
}
.skin-1 .nav-list > li.active > .submenu li.active > a:after {
  top: 5px;
}
.skin-1 .nav-list > li .submenu {
  background-color: #333D3F;
}
.skin-1 .nav-list > li.active .submenu {
  background-color: #181E20;
}
.skin-1 .nav-list > li .submenu > li > a {
  border-top-style: solid;
  border-top-color: #454D4E;
  background-color: #333D3F;
}
.skin-1 .nav-list > li .submenu > li > a:hover {
  background-color: #2D3638;
}
.skin-1 .nav-list > li.active .submenu > li > a {
  border-top-color: #222526;
  background-color: #181E20;
}
.skin-1 .nav-list > li.active .submenu > li > a:hover {
  background-color: #14191a;
}
.skin-1 .nav-list > li > .submenu {
  border-top-color: #505A5B;
}
.skin-1 .nav-list > li.active > .submenu {
  border-top-color: #2F3E44;
}
.skin-1 .nav-list > li > .submenu > li > a {
  color: #D9DFE6;
}
.skin-1 .nav-list > li > .submenu > li:hover > a {
  color: #8AB4DE;
}
.skin-1 .nav-list > li > .submenu > li.active > a {
  color: #7BB7E5;
  background-color: #161b1d;
}
.skin-1 .nav-list > li > .submenu:before,
.skin-1 .nav-list > li > .submenu > li:before {
  border-color: #646C70;
}
.skin-1 .nav-list > li.active > .submenu:before,
.skin-1 .nav-list > li.active > .submenu > li:before {
  border-color: #3F4E54;
}
.skin-1 .nav-list > li .submenu li > .submenu li > a {
  color: #D9DFE6;
}
.skin-1 .nav-list > li .submenu li > .submenu li:hover > a {
  color: #8AB4DE;
}
.skin-1 .nav-list > li .submenu li.open > a,
.skin-1 .nav-list > li .submenu li > .submenu > li.open > a {
  color: #85C0EC;
}
.skin-1 .nav-list > li .submenu li > .submenu li.active > a {
  color: #61A8DD;
}
.skin-1 .nav-list > li .submenu li > .submenu li.active:hover > a {
  color: #85C0EC;
}
.skin-1 .sidebar .nav-list > li.active.highlight,
.skin-1 .sidebar .nav-list > li.active.highlight + li {
  border-color: #506B7F;
}
.skin-1 .sidebar .nav-list > li.active.highlight + li:last-child {
  border-bottom-color: #3F4E54;
}
.skin-1 .sidebar .nav-list > li.active.highlight > a:after {
  border-right-color: transparent;
  border-left-color: #141A1B;
  border-width: 20px 0 20px 10px;
  z-index: 1;
  top: 0;
  right: -9px;
}
.skin-1 .sidebar .nav-list > li.active.highlight > a:before {
  border-left-color: #506B7F;
  border-style: solid;
  border-color: transparent;
  border-width: 20px 0 20px 10px;
  content: "";
  position: absolute;
  right: -11px;
  top: 0;
  z-index: 1;
}
.skin-1 .sidebar-shortcuts,
.skin-1 .sidebar-shortcuts-mini {
  background-color: #222A2D;
  border-color: #3F4E54;
}
.skin-1 .sidebar > .nav-search {
  background-color: #222A2D;
  border-color: #3F4E54;
}
.skin-1 .sidebar-toggle {
  background-color: #181e21;
  border-color: #3F4E54;
}
.skin-1 .sidebar-toggle > .ace-icon {
  background-color: #222A2D;
  color: #AAA;
  border-color: #AAA;
}
.skin-1 .breadcrumbs {
  border-width: 0;
  background-color: #F0F0F0;
}
@media (min-width: 992px) {
  .skin-1 .breadcrumbs.breadcrumbs-fixed {
    border-bottom-width: 1px;
  }
}
@media only screen and (max-width: 991px) {
  .skin-1 .sidebar.responsive {
    border-width: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .skin-1 .sidebar.responsive .nav-list li.active > a:after {
    display: none;
  }
  .skin-1 .sidebar.responsive .nav-list > li.active.highlight > a:after {
    display: block;
  }
}
.skin-1 .sidebar.menu-min .nav-list > li.open > a {
  background-color: #222A2D;
  color: #bac2c8;
}
.skin-1 .sidebar.menu-min .nav-list > li.active > a:after {
  border-width: 9px 6px;
  top: 10px;
}
.skin-1 .sidebar.menu-min .nav-list > li.active.highlight > a:after {
  border-width: 20px 0 20px 10px;
  top: 0;
}
.skin-1 .sidebar.menu-min .nav-list > li.active:hover > a:after {
  border-right-color: #242A2B;
}
.skin-1 .sidebar.menu-min .nav-list > li.active > a,
.skin-1 .sidebar.menu-min .nav-list > li.active > a:hover {
  background-color: #141A1B;
  color: #7BB7E5;
}
.skin-1 .sidebar.menu-min .nav-list > li:hover > a {
  color: #E1EAF1;
}
.skin-1 .sidebar.menu-min .nav-list > li > a > .menu-text {
  background-color: #414B51;
}
.skin-1 .sidebar.menu-min .nav-list > li.active > a > .menu-text {
  background-color: #242A2B;
  border: 0px solid #3B4547;
  border-width: 1px 1px 0;
  border-left-color: #305675;
}
.skin-1 .sidebar.menu-min .nav-list > li.active > a:not(.dropdown-toggle) > .menu-text {
  border-width: 1px;
  border-top-color: #4a5c63;
  border-bottom-color: #4a5c63;
  border-right-color: #4a5c63;
}
.skin-1 .sidebar.menu-min .nav-list > li.active:hover > a,
.skin-1 .sidebar.menu-min .nav-list > li.active > a > .menu-text {
  color: #7BB7E5;
}
.skin-1 .sidebar.menu-min .nav-list > li.open.active > a {
  background-color: #141A1B;
}
.skin-1 .sidebar.menu-min .nav-list > li > a > .menu-text,
.skin-1 .sidebar.menu-min .nav-list > li > .submenu {
  border-width: 0;
  border-left: 1px solid #181D1F;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.skin-1 .sidebar.menu-min .nav-list > li > .submenu {
  border-top: 1px solid #3F4E54;
}
.skin-1 .sidebar.menu-min .nav-list > li > .submenu:after {
  display: none;
}
.skin-1 .sidebar.menu-min .nav-list > li > .submenu {
  background-color: #333D3F;
  border-top-color: #5A606A;
  border-top-width: 1px !important;
}
.skin-1 .sidebar.menu-min .nav-list > li.active > .submenu {
  background-color: #181E20;
  border-top-color: #3B4547;
  border-left-color: #305675;
}
.skin-1 .sidebar.menu-min .nav-list > li > .arrow:after {
  border-right-color: #414B51;
}
.skin-1 .sidebar.menu-min .nav-list > li > .arrow:before {
  border-right-color: #111415;
}
.skin-1 .sidebar.menu-min .nav-list > li.active > .arrow:after {
  border-right-color: #242A2B;
}
.skin-1 .sidebar.menu-min .nav-list > li.active > .arrow:before {
  border-right-color: #50a0dd;
}
.skin-1 .sidebar.menu-min .sidebar-shortcuts-large {
  background-color: #222A2D;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #4a5c63;
  border-width: 0 1px 1px 0;
  top: 0;
}
@media (max-width: 991px) {
  .skin-1 .sidebar.responsive-min .nav-list > li.open > a {
    background-color: #222A2D;
    color: #bac2c8;
  }
  .skin-1 .sidebar.responsive-min .nav-list > li.active > a:after {
    border-width: 9px 6px;
    top: 10px;
  }
  .skin-1 .sidebar.responsive-min .nav-list > li.active.highlight > a:after {
    border-width: 20px 0 20px 10px;
    top: 0;
  }
  .skin-1 .sidebar.responsive-min .nav-list > li.active:hover > a:after {
    border-right-color: #242A2B;
  }
  .skin-1 .sidebar.responsive-min .nav-list > li.active > a,
  .skin-1 .sidebar.responsive-min .nav-list > li.active > a:hover {
    background-color: #141A1B;
    color: #7BB7E5;
  }
  .skin-1 .sidebar.responsive-min .nav-list > li:hover > a {
    color: #E1EAF1;
  }
  .skin-1 .sidebar.responsive-min .nav-list > li > a > .menu-text {
    background-color: #414B51;
  }
  .skin-1 .sidebar.responsive-min .nav-list > li.active > a > .menu-text {
    background-color: #242A2B;
    border: 0px solid #3B4547;
    border-width: 1px 1px 0;
    border-left-color: #305675;
  }
  .skin-1 .sidebar.responsive-min .nav-list > li.active > a:not(.dropdown-toggle) > .menu-text {
    border-width: 1px;
    border-top-color: #4a5c63;
    border-bottom-color: #4a5c63;
    border-right-color: #4a5c63;
  }
  .skin-1 .sidebar.responsive-min .nav-list > li.active:hover > a,
  .skin-1 .sidebar.responsive-min .nav-list > li.active > a > .menu-text {
    color: #7BB7E5;
  }
  .skin-1 .sidebar.responsive-min .nav-list > li.open.active > a {
    background-color: #141A1B;
  }
  .skin-1 .sidebar.responsive-min .nav-list > li > a > .menu-text,
  .skin-1 .sidebar.responsive-min .nav-list > li > .submenu {
    border-width: 0;
    border-left: 1px solid #181D1F;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .skin-1 .sidebar.responsive-min .nav-list > li > .submenu {
    border-top: 1px solid #3F4E54;
  }
  .skin-1 .sidebar.responsive-min .nav-list > li > .submenu:after {
    display: none;
  }
  .skin-1 .sidebar.responsive-min .nav-list > li > .submenu {
    background-color: #333D3F;
    border-top-color: #5A606A;
    border-top-width: 1px !important;
  }
  .skin-1 .sidebar.responsive-min .nav-list > li.active > .submenu {
    background-color: #181E20;
    border-top-color: #3B4547;
    border-left-color: #305675;
  }
  .skin-1 .sidebar.responsive-min .nav-list > li > .arrow:after {
    border-right-color: #414B51;
  }
  .skin-1 .sidebar.responsive-min .nav-list > li > .arrow:before {
    border-right-color: #111415;
  }
  .skin-1 .sidebar.responsive-min .nav-list > li.active > .arrow:after {
    border-right-color: #242A2B;
  }
  .skin-1 .sidebar.responsive-min .nav-list > li.active > .arrow:before {
    border-right-color: #50a0dd;
  }
  .skin-1 .sidebar.responsive-min .sidebar-shortcuts-large {
    background-color: #222A2D;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #4a5c63;
    border-width: 0 1px 1px 0;
    top: 0;
  }
  .skin-1 .sidebar.responsive-min .nav-list > li.active > a:after {
    display: block;
  }
  .skin-1 .sidebar.responsive-max .nav-list li.active > a:after {
    display: none;
  }
  .skin-1 .sidebar.responsive-max .nav-list > li.highlight.active > a:after {
    display: block;
  }
}
@media only screen and (min-width: 992px) {
  .skin-1 .nav-list li.hover > .submenu {
    padding-left: 0;
    padding-bottom: 2px;
    padding-right: 0;
    border-color: #232828;
  }
  .skin-1 .nav-list li.hover > .submenu > li > a {
    border-top-width: 1px;
    margin-bottom: 0;
    margin-top: 0;
  }
  .skin-1 .nav-list li.hover > .submenu > li:first-child > a {
    border-top-width: 0;
  }
  .skin-1 .nav-list > li > .submenu li.hover > .submenu {
    padding: 3px 2px;
  }
  .skin-1 .nav-list > li.active > .submenu li.hover > .submenu {
    border-left-color: #475561;
  }
  .skin-1 .nav-list li.hover.active > .submenu,
  .skin-1 .nav-list li.active > .submenu li.hover.active > .submenu {
    border-left-color: #305675;
  }
  .skin-1 .nav-list > li.active > .submenu li.hover > .submenu {
    background-color: #171E1F;
  }
}
@media only screen and (min-width: 992px) {
  .skin-1 .sidebar.navbar-collapse .nav-list > li.open.hover:not(:hover):not(:focus):not(.active) > a {
    color: #bac2c8;
  }
  .skin-1 .sidebar.navbar-collapse .nav-list > li.open.hover:hover > a {
    color: #E1EAF1;
    background-color: #414B51;
  }
  .skin-1 .sidebar.navbar-collapse .nav-list > li.active.hover:hover > a {
    color: #7BB7E5;
    background-color: #141A1B;
  }
  .skin-1 .sidebar.navbar-collapse .nav-list > li > .submenu li.open.hover:not(:hover):not(:focus):not(.active) > a {
    color: #D9DFE6;
  }
}
@media only screen and (min-width: 992px) and (max-width: 991px) {
  .skin-1 .sidebar.navbar-collapse {
    /**.nav-list li li:hover > a {
		background-color: @submenu-item-background;
	}
	.nav-list li li > a:hover,
	.nav-list li li.open > a:hover {
		background-color: @submenu-item-hover-background;
	}

	.nav-list > li.active li:hover > a {
		background-color: @submenu-item-background-active;
	}
	.nav-list > li.active li > a:hover {
		background-color: @submenu-item-background-active-hover;
	}*/
  }
  .skin-1 .sidebar.navbar-collapse .nav-list li.hover > .submenu {
    padding-bottom: 0;
  }
  .skin-1 .sidebar.navbar-collapse .nav-list li.hover > .submenu {
    border-top-color: #505A5B;
  }
  .skin-1 .sidebar.navbar-collapse .nav-list li.hover.active > .submenu {
    border-top-color: #2F3E44;
  }
  .skin-1 .sidebar.navbar-collapse .nav-list li.hover.active > .submenu,
  .skin-1 .sidebar.navbar-collapse .nav-list > li > .submenu li.hover > .submenu,
  .skin-1 .sidebar.navbar-collapse .nav-list li.active > .submenu li.hover.active > .submenu {
    border-left-width: 0;
  }
  .skin-1 .sidebar.navbar-collapse .nav-list > li > .submenu li.hover > .submenu {
    padding: 0;
  }
  .skin-1 .sidebar.navbar-collapse .nav-list li li.hover > .submenu > li:first-child > a {
    border-top-width: 1px;
  }
}
.skin-1 .sidebar.navbar-collapse .nav-list > li .submenu > li.hover:hover > a {
  background-color: #2D3638;
}
.skin-1 .sidebar.navbar-collapse .nav-list > li.active .submenu > li.hover:hover > a {
  background-color: #14191a;
}
@media only screen and (min-width: 992px) {
  .skin-1 .sidebar:not(.navbar-collapse) .nav-list > li .submenu > li.hover:hover > a {
    background-color: #2D3638;
  }
  .skin-1 .sidebar:not(.navbar-collapse) .nav-list > li.active .submenu > li.hover:hover > a {
    background-color: #14191a;
  }
}
.skin-1 .nav-list li > .arrow:after {
  border-right-color: #353C3D;
}
.skin-1 .nav-list li > .arrow:before {
  border-right-color: #1d2122;
}
.skin-1 .nav-list > li.active > .arrow:after {
  border-right-color: #171E1F;
}
.skin-1 .nav-list > li.active > .arrow:before {
  border-right-color: #305675;
}
.skin-1 .nav-list > li.active > .submenu li.hover > .arrow:before {
  border-right-color: #4f6e86;
}
.skin-1 .nav-list > li.active > .submenu li.hover.active > .arrow:before {
  border-right-color: #305675;
}
.skin-1 .nav-list > li.active > .submenu li.hover > .arrow:after {
  border-right-color: #171E1F;
}
.skin-1 .nav-list li.pull_up > .arrow:after {
  border-right-color: #353C3D !important;
}
.skin-1 .nav-list li.pull_up > .arrow:before {
  border-right-color: #1d2122 !important;
}
.skin-1 .nav-list li.pull_up.active > .arrow:after {
  border-right-color: #171E1F !important;
}
.skin-1 .nav-list li.pull_up.active > .arrow:before {
  border-right-color: #305675 !important;
}
.skin-1 .main-container .menu-toggler {
  background-color: #444;
}
.skin-1 .main-container .menu-toggler:before {
  border-top-color: #87B87F;
  border-bottom-color: #6FB3E0;
}
.skin-1 .main-container .menu-toggler:after {
  border-top-color: #FFA24D;
  border-bottom-color: #D15B47;
}
.skin-1 .main-container .menu-toggler > .toggler-text {
  border-top-color: #444;
}
.skin-1.display {
  background-color: #5d82a2;
}
.skin-1.display:before {
  border-top-color: #b5d3b0;
  border-bottom-color: #aed4ee;
}
.skin-1.display:after {
  border-top-color: #ffca9a;
  border-bottom-color: #e09284;
}
.skin-1.display > .toggler-text {
  border-top-color: #5d82a2;
}
.skin-1 .navbar .navbar-toggle {
  background-color: #4D9DCC;
}
.skin-1 .navbar .navbar-toggle:focus {
  background-color: #4D9DCC;
  border-color: transparent;
}
.skin-1 .navbar .navbar-toggle:hover {
  background-color: #3992c6;
  border-color: rgba(255, 255, 255, 0.1);
}
.skin-1 .navbar .navbar-toggle.display,
.skin-1 .navbar .navbar-toggle[data-toggle=collapse]:not(.collapsed) {
  background-color: #3384b3;
  box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.25);
  border-color: rgba(255, 255, 255, 0.35);
}
@media only screen and (min-width: 768px) {
  .skin-1 .container.main-container:before {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
  }
}
@media only screen and (min-width: 992px) {
  .skin-1 .sidebar.h-sidebar:before {
    background-color: #38424a;
    border-bottom-color: #4a5c63;
  }
  .skin-1 .sidebar.h-sidebar .nav-list {
    border-bottom-width: 0;
  }
  .skin-1 .sidebar.h-sidebar .nav-list > li.active:before {
    display: block;
  }
  .skin-1 .sidebar.h-sidebar .nav-list > li.hover.active > a:after {
    border-color: transparent transparent #FFF;
    border-width: 8px;
    top: auto;
    bottom: 0;
    right: auto;
    left: 50%;
    margin-left: -8px;
    content: "";
    display: block;
  }
  .skin-1 .sidebar.h-sidebar .nav-list > li.hover.active:hover > a:after,
  .skin-1 .sidebar.h-sidebar .nav-list > li.hover.active.hover-show > a:after {
    display: none;
  }
  .skin-1 .sidebar.h-sidebar + .main-content .breadcrumbs {
    border-color: #E8E8E8;
  }
  .skin-1 .sidebar.h-sidebar .nav-list > li {
    border-color: #3F4E54;
  }
  .skin-1 .sidebar.h-sidebar .nav-list > li:hover,
  .skin-1 .sidebar.h-sidebar .nav-list > li:hover + li {
    border-left-color: #506575;
  }
  .skin-1 .sidebar.h-sidebar .nav-list > li:last-child:hover {
    border-right-color: #506575;
  }
  .skin-1 .sidebar.h-sidebar .nav-list > li.active,
  .skin-1 .sidebar.h-sidebar .nav-list > li.active + li,
  .skin-1 .sidebar.h-sidebar .nav-list > li:hover + li.active {
    border-left-color: #55738a;
  }
  .skin-1 .sidebar.h-sidebar .nav-list > li.active:last-child {
    border-right-color: #55738a;
  }
  .skin-1 .sidebar.h-sidebar .nav-list > li.hover > .submenu {
    border-top-color: #232828;
  }
  .skin-1 .sidebar.h-sidebar .nav-list > li.hover.active > .submenu {
    border-top-color: #305675;
    border-left-color: #232828;
  }
  .skin-1 .sidebar.h-sidebar .sidebar-shortcuts-large {
    background-color: #222A2D;
    border: 1px solid #232828;
    border-top-color: #305675;
    top: 100%;
  }
  .skin-1 .sidebar.h-sidebar .nav-list > li > .arrow:after {
    border-right-color: transparent;
    border-bottom-color: #353C3D;
  }
  .skin-1 .sidebar.h-sidebar .nav-list > li > .arrow:before {
    border-right-color: transparent;
    border-bottom-color: #191c1c;
  }
  .skin-1 .sidebar.h-sidebar .nav-list > li.active > .arrow:before,
  .skin-1 .sidebar.h-sidebar .sidebar-shortcuts-large:before {
    border-right-color: transparent;
    border-bottom-color: #50a0dd;
  }
  .skin-1 .sidebar.h-sidebar .nav-list > li.active > .arrow:after,
  .skin-1 .sidebar.h-sidebar .sidebar-shortcuts-large:after {
    border-right-color: transparent;
    border-bottom-color: #171E1F;
  }
  .skin-1 .sidebar.h-sidebar .sidebar-shortcuts-large:after {
    border-bottom-color: #222A2D;
  }
  .skin-1 .sidebar.h-sidebar .nav-list > li.highlight.active > a:before {
    display: none;
  }
  .skin-1 .sidebar.h-sidebar.menu-min .nav-list > li > a > .menu-text {
    border-width: 1px 0 0;
    border-top-color: #181D1F;
  }
  .skin-1 .sidebar.h-sidebar.menu-min .nav-list > li.active > a > .menu-text {
    border-top-color: #305675;
  }
  .skin-1 .sidebar.h-sidebar.menu-min .nav-list > li.active > .submenu {
    border-top-color: #3B4547;
  }
  .skin-1 .sidebar.h-sidebar.menu-min .nav-list > li > .arrow:after {
    border-bottom-color: #414B51;
  }
  .skin-1 .sidebar.h-sidebar.menu-min .nav-list > li > .arrow:before {
    border-bottom-color: #111415;
  }
  .skin-1 .sidebar.h-sidebar.menu-min .nav-list > li.active > .arrow:after {
    border-bottom-color: #242A2B;
  }
  .skin-1 .sidebar.h-sidebar.menu-min .nav-list > li.active > .arrow:before {
    border-bottom-color: #50a0dd;
  }
  .skin-1 .h-sidebar.sidebar-fixed + .main-content {
    padding-top: 86px;
  }
  .skin-1 .h-sidebar.sidebar-fixed.no-gap + .main-content {
    padding-top: 72px;
  }
  .skin-1 .h-sidebar.sidebar-fixed.menu-min + .main-content {
    padding-top: 61px;
  }
  .skin-1 .h-sidebar.sidebar-fixed.menu-min.no-gap + .main-content {
    padding-top: 47px;
  }
  .skin-1 .main-content .h-sidebar.sidebar .nav-list {
    border-left: 1px solid #4a5c63;
  }
}
.skin-1 .sidebar-scroll .sidebar-shortcuts {
  border-bottom-color: #4a5c63;
}
.skin-1 .sidebar-scroll .sidebar-toggle {
  border-top-color: #4a5c63;
}
.skin-1 .scrollout .scroll-track {
  background-color: transparent;
}
.skin-1 .scrollout .scroll-bar {
  background-color: #CCC;
  background-color: rgba(0, 0, 0, 0.2);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .skin-1 .navbar.navbar-collapse {
    background-color: transparent;
  }
  .skin-1 .navbar.navbar-collapse:before,
  .skin-1 .navbar.navbar-collapse .navbar-container {
    background: #2C6AA0;
  }
}
.skin-1 .nav-list > li.disabled:before {
  display: none !important;
}
.skin-1 .nav-list > li.disabled > a {
  background-color: #333 !important;
  color: #AAA !important;
}
.skin-1 .nav-list li .submenu > li.disabled > a,
.skin-1 .nav-list li.disabled .submenu > li > a {
  background-color: #444 !important;
  color: #A0A0A0 !important;
  cursor: not-allowed !important;
}
.skin-1 .nav-list li .submenu > li.disabled > a > .menu-icon,
.skin-1 .nav-list li.disabled .submenu > li > a > .menu-icon {
  display: none;
}
.skin-1 .nav-list > li.disabled .submenu > li > a {
  border-top-color: #505050;
}
.skin-2 {
  background-color: #5C5C5C;
}
.skin-2 .navbar {
  background: #C6487E;
}
.skin-2 .sidebar {
  background-color: #505050;
  border-right-width: 0;
}
.skin-2 .nav-list > li {
  border-color: #484848;
}
.skin-2 .nav-list > li:first-child {
  border-top-color: #5a5a5a;
}
.skin-2 .nav-list > li:last-child {
  border-bottom-color: #626262;
}
.skin-2 .nav-list > li > a {
  background-color: #393939;
  color: #E6E6E6;
}
.skin-2 .nav-list > li > a > .menu-text,
.skin-2 .nav-list > li > a > .arrow {
  color: #E6E6E6;
}
.skin-2 .nav-list > li:hover > a,
.skin-2 .nav-list > li.hover-show > a {
  background-color: #bd5289;
}
.skin-2 .nav-list > li:hover > a > .menu-text,
.skin-2 .nav-list > li.hover-show > a > .menu-text {
  font-weight: bold;
  color: #FFF;
  color: rgba(0, 0, 0, 0.6);
}
.skin-2 .nav-list > li:hover > a > .menu-icon,
.skin-2 .nav-list > li.hover-show > a > .menu-icon,
.skin-2 .nav-list > li:hover > a > .arrow,
.skin-2 .nav-list > li.hover-show > a > .arrow {
  color: #FFF;
}
.skin-2 .nav-list > li.open > a {
  background-color: #bd5289;
}
.skin-2 .nav-list > li.open > a > .menu-text {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
}
.skin-2 .nav-list > li.open > a > .menu-icon,
.skin-2 .nav-list > li.open > a > .arrow {
  color: #FFF;
}
.skin-2 .nav-list > li.active > a {
  background-color: #242424;
  color: #FFF;
}
.skin-2 .nav-list > li.active > a > .menu-icon {
  color: inherit;
}
.skin-2 .nav-list > li.active > a > .menu-text,
.skin-2 .nav-list > li.active > a > .arrow {
  color: #FFF;
}
.skin-2 .nav-list > li.active:hover > a,
.skin-2 .nav-list > li.active:not(.highlight):hover > a,
.skin-2 .nav-list > li.active.hover-show > a,
.skin-2 .nav-list > li.active:not(.highlight).hover-show > a,
.skin-2 .nav-list > li.active.open > a,
.skin-2 .nav-list > li.active.open:not(.highlight) > a {
  background-color: #242424;
}
.skin-2 .nav-list > li.active.highlight > a {
  background-color: #bd5289;
}
.skin-2 .nav-list > li.active.highlight > a > .menu-icon {
  color: #FFF;
}
.skin-2 .nav-list li.active > a:after {
  border-right-color: #FFF;
  border-width: 11px 7px;
  top: 8px;
  right: 0;
}
.skin-2 .nav-list > li.active > .submenu li.active > a:after {
  top: 6px;
}
.skin-2 .nav-list > li:before {
  top: 0;
  height: 39px;
}
.skin-2 .nav-list > li.active:before {
  display: block;
  background-color: #ce7da7;
}
.skin-2 .nav-list > li.active.highlight:before,
.skin-2 .nav-list > li:before {
  background-color: #dea8c4;
}
.skin-2 .nav-list > li > .submenu {
  border-top-width: 0;
  background-color: #45303b;
}
.skin-2 .nav-list > li > .submenu > li,
.skin-2 .nav-list > li > .submenu > li > .submenu,
.skin-2 .nav-list > li > .submenu > li > .submenu > li,
.skin-2 .nav-list > li > .submenu > li > .submenu > li .submenu,
.skin-2 .nav-list > li > .submenu > li > .submenu > li .submenu li {
  background-color: inherit;
}
.skin-2 .nav-list > li .submenu > li > a {
  border-top: 1px solid;
  border-top-color: #555;
  border-top-color: rgba(255, 255, 255, 0.08);
  background-color: transparent;
}
.skin-2 .nav-list > li .submenu > li > a:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #FFF;
}
.skin-2 .nav-list > li .submenu > li > a:hover > .menu-icon {
  background-color: transparent;
}
.skin-2 .nav-list > li.active .submenu > li > a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.skin-2 .nav-list > li > .submenu > li > a {
  color: #EEE;
}
.skin-2 .nav-list > li > .submenu > li:first-child > a {
  border-top-width: 0;
}
.skin-2 .nav-list > li > .submenu > li.active > a {
  background-color: rgba(0, 0, 0, 0.05);
  font-weight: bold;
}
.skin-2 .nav-list > li > .submenu > li.active > a > .menu-icon {
  color: inherit;
  background-color: transparent;
}
.skin-2 .nav-list li .submenu li > a {
  color: #EEE;
  font-size: 13px;
}
.skin-2 .nav-list > li > .submenu:before,
.skin-2 .nav-list > li > .submenu > li:before {
  display: none;
}
.skin-2 .nav-list > li .submenu > li > a {
  padding-left: 32px;
}
.skin-2 .nav-list > li .submenu > li > a > .menu-icon {
  left: 12px;
}
@media only screen and (min-width: 992px) {
  .skin-2 .nav-list > li .submenu > li.hover > a {
    padding-left: 26px;
  }
  .skin-2 .nav-list > li .submenu > li.hover > a > .menu-icon {
    left: 6px;
  }
}
.skin-2 .nav-list > li > .submenu li > .submenu > li > a {
  margin-left: 0;
  padding-left: 37px;
  padding-top: 8px;
  padding-bottom: 10px;
  border-top-width: 0;
}
.skin-2 .nav-list > li > .submenu li > .submenu > li > .submenu > li > a {
  margin-left: 0;
  padding-left: 51px;
}
@media only screen and (min-width: 992px) {
  .skin-2 .nav-list > li > .submenu li > .submenu > li.hover > a {
    border-top-width: 1px;
    padding-bottom: 9px;
  }
  .skin-2 .nav-list > li > .submenu li > .submenu > li.hover:first-child > a {
    border-top-width: 0;
  }
}
.skin-2 .nav-list > li > .submenu li:hover > a,
.skin-2 .nav-list > li > .submenu li.hover-show > a,
.skin-2 .nav-list > li > .submenu li.active > a,
.skin-2 .nav-list > li > .submenu li.open > a {
  color: #da81af;
}
.skin-2 .nav-list > li > .submenu li > a > .ace-icon {
  background-color: transparent;
}
.skin-2 .nav-list > li:hover:not(.active) > a .badge,
.skin-2 .nav-list > li.hover-show:not(.active) > a .badge,
.skin-2 .nav-list > li.open:not(.active) > a .badge,
.skin-2 .nav-list > li.active.highlight .badge,
.skin-2 .nav-list > li:hover:not(.active) > a .label,
.skin-2 .nav-list > li.hover-show:not(.active) > a .label,
.skin-2 .nav-list > li.open:not(.active) > a .label,
.skin-2 .nav-list > li.active.highlight .label {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.skin-2 .nav-list > li:hover:not(.active) > a .badge.badge-transparent,
.skin-2 .nav-list > li.hover-show:not(.active) > a .badge.badge-transparent,
.skin-2 .nav-list > li.open:not(.active) > a .badge.badge-transparent,
.skin-2 .nav-list > li.active.highlight .badge.badge-transparent,
.skin-2 .nav-list > li:hover:not(.active) > a .label.label-transparent,
.skin-2 .nav-list > li.hover-show:not(.active) > a .label.label-transparent,
.skin-2 .nav-list > li.open:not(.active) > a .label.label-transparent,
.skin-2 .nav-list > li.active.highlight .label.label-transparent {
  color: rgba(255, 255, 255, 0.8) !important;
  background-color: transparent !important;
}
.skin-2 .nav-list > li:hover:not(.active) > a .badge.badge-transparent .ace-icon,
.skin-2 .nav-list > li.hover-show:not(.active) > a .badge.badge-transparent .ace-icon,
.skin-2 .nav-list > li.open:not(.active) > a .badge.badge-transparent .ace-icon,
.skin-2 .nav-list > li.active.highlight .badge.badge-transparent .ace-icon,
.skin-2 .nav-list > li:hover:not(.active) > a .label.label-transparent .ace-icon,
.skin-2 .nav-list > li.hover-show:not(.active) > a .label.label-transparent .ace-icon,
.skin-2 .nav-list > li.open:not(.active) > a .label.label-transparent .ace-icon,
.skin-2 .nav-list > li.active.highlight .label.label-transparent .ace-icon {
  color: rgba(255, 255, 255, 0.8) !important;
}
.skin-2 .nav-list li > .arrow:after {
  border-right-color: #45303b;
}
.skin-2 .nav-list li > .arrow:before {
  border-right-color: #191919;
}
.skin-2 .nav-list > li.pull_up > .arrow:after {
  border-right-color: #45303b !important;
}
.skin-2 .nav-list > li.pull_up > .arrow:before {
  border-right-color: #191919 !important;
}
.skin-2 .nav-list > li.active.highlight > a:after {
  border-right-color: transparent;
  border-left-color: #bd5289;
  border-width: 20px 0 20px 10px;
  z-index: 1;
  top: 0;
  right: -9px;
  display: block;
}
.skin-2 .nav-list > li:nth-child(1) > a {
  color: #00BE67;
}
.skin-2 .nav-list > li:nth-child(1):hover > a,
.skin-2 .nav-list > li:nth-child(1).hover-show > a,
.skin-2 .nav-list > li.open:nth-child(1) > a {
  background-color: #18a665;
}
.skin-2 .nav-list > li:nth-child(1) > .submenu {
  background-color: #2a4137;
}
.skin-2 .nav-list > li:nth-child(1) > .arrow:after,
.skin-2 .nav-list > li:nth-child(1) li > .arrow:after {
  border-right-color: #2a4137;
}
.skin-2 .nav-list > li.pull_up:nth-child(1) > .arrow:after {
  border-right-color: #2a4137 !important;
}
.skin-2 .nav-list > li:nth-child(1) > .submenu li:hover > a,
.skin-2 .nav-list > li:nth-child(1) > .submenu li.hover-show > a,
.skin-2 .nav-list > li:nth-child(1) > .submenu li.active > a,
.skin-2 .nav-list > li:nth-child(1) > .submenu li.open > a {
  color: #1eed8e;
}
.skin-2 .nav-list > li.active:nth-child(1):before {
  background-color: #ce7da7;
  background-color: #52bc8c;
}
.skin-2 .nav-list > li.active.highlight:nth-child(1):before,
.skin-2 .nav-list > li:nth-child(1):before {
  background-color: #8bd3b2;
}
.skin-2 .nav-list > li:nth-child(2) > a {
  color: #5ED02B;
}
.skin-2 .nav-list > li:nth-child(2):hover > a,
.skin-2 .nav-list > li:nth-child(2).hover-show > a,
.skin-2 .nav-list > li.open:nth-child(2) > a {
  background-color: #6ab14a;
}
.skin-2 .nav-list > li:nth-child(2) > .submenu {
  background-color: #36432f;
}
.skin-2 .nav-list > li:nth-child(2) > .arrow:after,
.skin-2 .nav-list > li:nth-child(2) li > .arrow:after {
  border-right-color: #36432f;
}
.skin-2 .nav-list > li.pull_up:nth-child(2) > .arrow:after {
  border-right-color: #36432f !important;
}
.skin-2 .nav-list > li:nth-child(2) > .submenu li:hover > a,
.skin-2 .nav-list > li:nth-child(2) > .submenu li.hover-show > a,
.skin-2 .nav-list > li:nth-child(2) > .submenu li.active > a,
.skin-2 .nav-list > li:nth-child(2) > .submenu li.open > a {
  color: #92d275;
}
.skin-2 .nav-list > li.active:nth-child(2):before {
  background-color: #ce7da7;
  background-color: #8fc478;
}
.skin-2 .nav-list > li.active.highlight:nth-child(2):before,
.skin-2 .nav-list > li:nth-child(2):before {
  background-color: #b4d8a5;
}
.skin-2 .nav-list > li:nth-child(3) > a {
  color: #A7D700;
}
.skin-2 .nav-list > li:nth-child(3):hover > a,
.skin-2 .nav-list > li:nth-child(3).hover-show > a,
.skin-2 .nav-list > li.open:nth-child(3) > a {
  background-color: #98bc1b;
}
.skin-2 .nav-list > li:nth-child(3) > .submenu {
  background-color: #3e442a;
}
.skin-2 .nav-list > li:nth-child(3) > .arrow:after,
.skin-2 .nav-list > li:nth-child(3) li > .arrow:after {
  border-right-color: #3e442a;
}
.skin-2 .nav-list > li.pull_up:nth-child(3) > .arrow:after {
  border-right-color: #3e442a !important;
}
.skin-2 .nav-list > li:nth-child(3) > .submenu li:hover > a,
.skin-2 .nav-list > li:nth-child(3) > .submenu li.hover-show > a,
.skin-2 .nav-list > li:nth-child(3) > .submenu li.active > a,
.skin-2 .nav-list > li:nth-child(3) > .submenu li.open > a {
  color: #c5ef35;
}
.skin-2 .nav-list > li.active:nth-child(3):before {
  background-color: #ce7da7;
  background-color: #b2cd54;
}
.skin-2 .nav-list > li.active.highlight:nth-child(3):before,
.skin-2 .nav-list > li:nth-child(3):before {
  background-color: #ccde8d;
}
.skin-2 .nav-list > li:nth-child(4) > a {
  color: #CEE100;
}
.skin-2 .nav-list > li:nth-child(4):hover > a,
.skin-2 .nav-list > li:nth-child(4).hover-show > a,
.skin-2 .nav-list > li.open:nth-child(4) > a {
  background-color: #b7c51c;
}
.skin-2 .nav-list > li:nth-child(4) > .submenu {
  background-color: #43452a;
}
.skin-2 .nav-list > li:nth-child(4) > .arrow:after,
.skin-2 .nav-list > li:nth-child(4) li > .arrow:after {
  border-right-color: #43452a;
}
.skin-2 .nav-list > li.pull_up:nth-child(4) > .arrow:after {
  border-right-color: #43452a !important;
}
.skin-2 .nav-list > li:nth-child(4) > .submenu li:hover > a,
.skin-2 .nav-list > li:nth-child(4) > .submenu li.hover-show > a,
.skin-2 .nav-list > li:nth-child(4) > .submenu li.active > a,
.skin-2 .nav-list > li:nth-child(4) > .submenu li.open > a {
  color: #e0ef3e;
}
.skin-2 .nav-list > li.active:nth-child(4):before {
  background-color: #ce7da7;
  background-color: #c9d355;
}
.skin-2 .nav-list > li.active.highlight:nth-child(4):before,
.skin-2 .nav-list > li:nth-child(4):before {
  background-color: #dbe28e;
}
.skin-2 .nav-list > li:nth-child(5) > a {
  color: #F8C600;
}
.skin-2 .nav-list > li:nth-child(5):hover > a,
.skin-2 .nav-list > li:nth-child(5).hover-show > a,
.skin-2 .nav-list > li.open:nth-child(5) > a {
  background-color: #d9b41f;
}
.skin-2 .nav-list > li:nth-child(5) > .submenu {
  background-color: #48422a;
}
.skin-2 .nav-list > li:nth-child(5) > .arrow:after,
.skin-2 .nav-list > li:nth-child(5) li > .arrow:after {
  border-right-color: #48422a;
}
.skin-2 .nav-list > li.pull_up:nth-child(5) > .arrow:after {
  border-right-color: #48422a !important;
}
.skin-2 .nav-list > li:nth-child(5) > .submenu li:hover > a,
.skin-2 .nav-list > li:nth-child(5) > .submenu li.hover-show > a,
.skin-2 .nav-list > li:nth-child(5) > .submenu li.active > a,
.skin-2 .nav-list > li:nth-child(5) > .submenu li.open > a {
  color: #f1d153;
}
.skin-2 .nav-list > li.active:nth-child(5):before {
  background-color: #ce7da7;
  background-color: #e3c657;
}
.skin-2 .nav-list > li.active.highlight:nth-child(5):before,
.skin-2 .nav-list > li:nth-child(5):before {
  background-color: #ecd98f;
}
.skin-2 .nav-list > li:nth-child(6) > a {
  color: #FFAB00;
}
.skin-2 .nav-list > li:nth-child(6):hover > a,
.skin-2 .nav-list > li:nth-child(6).hover-show > a,
.skin-2 .nav-list > li.open:nth-child(6) > a {
  background-color: #dfa020;
}
.skin-2 .nav-list > li:nth-child(6) > .submenu {
  background-color: #493f2a;
}
.skin-2 .nav-list > li:nth-child(6) > .arrow:after,
.skin-2 .nav-list > li:nth-child(6) li > .arrow:after {
  border-right-color: #493f2a;
}
.skin-2 .nav-list > li.pull_up:nth-child(6) > .arrow:after {
  border-right-color: #493f2a !important;
}
.skin-2 .nav-list > li:nth-child(6) > .submenu li:hover > a,
.skin-2 .nav-list > li:nth-child(6) > .submenu li.hover-show > a,
.skin-2 .nav-list > li:nth-child(6) > .submenu li.active > a,
.skin-2 .nav-list > li:nth-child(6) > .submenu li.open > a {
  color: #f2c05a;
}
.skin-2 .nav-list > li.active:nth-child(6):before {
  background-color: #ce7da7;
  background-color: #e7b858;
}
.skin-2 .nav-list > li.active.highlight:nth-child(6):before,
.skin-2 .nav-list > li:nth-child(6):before {
  background-color: #efd08f;
}
.skin-2 .nav-list > li:nth-child(7) > a {
  color: #FF6E00;
}
.skin-2 .nav-list > li:nth-child(7):hover > a,
.skin-2 .nav-list > li:nth-child(7).hover-show > a,
.skin-2 .nav-list > li.open:nth-child(7) > a {
  background-color: #df7220;
}
.skin-2 .nav-list > li:nth-child(7) > .submenu {
  background-color: #49372a;
}
.skin-2 .nav-list > li:nth-child(7) > .arrow:after,
.skin-2 .nav-list > li:nth-child(7) li > .arrow:after {
  border-right-color: #49372a;
}
.skin-2 .nav-list > li.pull_up:nth-child(7) > .arrow:after {
  border-right-color: #49372a !important;
}
.skin-2 .nav-list > li:nth-child(7) > .submenu li:hover > a,
.skin-2 .nav-list > li:nth-child(7) > .submenu li.hover-show > a,
.skin-2 .nav-list > li:nth-child(7) > .submenu li.active > a,
.skin-2 .nav-list > li:nth-child(7) > .submenu li.open > a {
  color: #f29b5a;
}
.skin-2 .nav-list > li.active:nth-child(7):before {
  background-color: #ce7da7;
  background-color: #e79658;
}
.skin-2 .nav-list > li.active.highlight:nth-child(7):before,
.skin-2 .nav-list > li:nth-child(7):before {
  background-color: #efb98f;
}
.skin-2 .nav-list > li:nth-child(8) > a {
  color: #F21C30;
}
.skin-2 .nav-list > li:nth-child(8):hover > a,
.skin-2 .nav-list > li:nth-child(8).hover-show > a,
.skin-2 .nav-list > li.open:nth-child(8) > a {
  background-color: #d43a48;
}
.skin-2 .nav-list > li:nth-child(8) > .submenu {
  background-color: #472e30;
}
.skin-2 .nav-list > li:nth-child(8) > .arrow:after,
.skin-2 .nav-list > li:nth-child(8) li > .arrow:after {
  border-right-color: #472e30;
}
.skin-2 .nav-list > li.pull_up:nth-child(8) > .arrow:after {
  border-right-color: #472e30 !important;
}
.skin-2 .nav-list > li:nth-child(8) > .submenu li:hover > a,
.skin-2 .nav-list > li:nth-child(8) > .submenu li.hover-show > a,
.skin-2 .nav-list > li:nth-child(8) > .submenu li.active > a,
.skin-2 .nav-list > li:nth-child(8) > .submenu li.open > a {
  color: #ea717c;
}
.skin-2 .nav-list > li.active:nth-child(8):before {
  background-color: #ce7da7;
  background-color: #df6b76;
}
.skin-2 .nav-list > li.active.highlight:nth-child(8):before,
.skin-2 .nav-list > li:nth-child(8):before {
  background-color: #ea9da4;
}
.skin-2 .nav-list > li:nth-child(9) > a {
  color: #DB348A;
}
.skin-2 .nav-list > li:nth-child(9):hover > a,
.skin-2 .nav-list > li:nth-child(9).hover-show > a,
.skin-2 .nav-list > li.open:nth-child(9) > a {
  background-color: #bd5289;
}
.skin-2 .nav-list > li:nth-child(9) > .submenu {
  background-color: #45303b;
}
.skin-2 .nav-list > li:nth-child(9) > .arrow:after,
.skin-2 .nav-list > li:nth-child(9) li > .arrow:after {
  border-right-color: #45303b;
}
.skin-2 .nav-list > li.pull_up:nth-child(9) > .arrow:after {
  border-right-color: #45303b !important;
}
.skin-2 .nav-list > li:nth-child(9) > .submenu li:hover > a,
.skin-2 .nav-list > li:nth-child(9) > .submenu li.hover-show > a,
.skin-2 .nav-list > li:nth-child(9) > .submenu li.active > a,
.skin-2 .nav-list > li:nth-child(9) > .submenu li.open > a {
  color: #da81af;
}
.skin-2 .nav-list > li.active:nth-child(9):before {
  background-color: #ce7da7;
}
.skin-2 .nav-list > li.active.highlight:nth-child(9):before,
.skin-2 .nav-list > li:nth-child(9):before {
  background-color: #dea8c4;
}
.skin-2 .nav-list > li:nth-child(10) > a {
  color: #C55EE7;
}
.skin-2 .nav-list > li:nth-child(10):hover > a,
.skin-2 .nav-list > li:nth-child(10).hover-show > a,
.skin-2 .nav-list > li.open:nth-child(10) > a {
  background-color: #b975d0;
}
.skin-2 .nav-list > li:nth-child(10) > .submenu {
  background-color: #423646;
}
.skin-2 .nav-list > li:nth-child(10) > .arrow:after,
.skin-2 .nav-list > li:nth-child(10) li > .arrow:after {
  border-right-color: #423646;
}
.skin-2 .nav-list > li.pull_up:nth-child(10) > .arrow:after {
  border-right-color: #423646 !important;
}
.skin-2 .nav-list > li:nth-child(10) > .submenu li:hover > a,
.skin-2 .nav-list > li:nth-child(10) > .submenu li.hover-show > a,
.skin-2 .nav-list > li:nth-child(10) > .submenu li.active > a,
.skin-2 .nav-list > li:nth-child(10) > .submenu li.open > a {
  color: #d9a9e9;
}
.skin-2 .nav-list > li.active:nth-child(10):before {
  background-color: #ce7da7;
  background-color: #cb98dc;
}
.skin-2 .nav-list > li.active.highlight:nth-child(10):before,
.skin-2 .nav-list > li:nth-child(10):before {
  background-color: #dcbae7;
}
.skin-2 .nav-list > li:nth-child(11) > a {
  color: #218BEE;
}
.skin-2 .nav-list > li:nth-child(11):hover > a,
.skin-2 .nav-list > li:nth-child(11).hover-show > a,
.skin-2 .nav-list > li.open:nth-child(11) > a {
  background-color: #3f8ad0;
}
.skin-2 .nav-list > li:nth-child(11) > .submenu {
  background-color: #2e3b47;
}
.skin-2 .nav-list > li:nth-child(11) > .arrow:after,
.skin-2 .nav-list > li:nth-child(11) li > .arrow:after {
  border-right-color: #2e3b47;
}
.skin-2 .nav-list > li.pull_up:nth-child(11) > .arrow:after {
  border-right-color: #2e3b47 !important;
}
.skin-2 .nav-list > li:nth-child(11) > .submenu li:hover > a,
.skin-2 .nav-list > li:nth-child(11) > .submenu li.hover-show > a,
.skin-2 .nav-list > li:nth-child(11) > .submenu li.active > a,
.skin-2 .nav-list > li:nth-child(11) > .submenu li.open > a {
  color: #74b0e7;
}
.skin-2 .nav-list > li.active:nth-child(11):before {
  background-color: #ce7da7;
  background-color: #6fa7dc;
}
.skin-2 .nav-list > li.active.highlight:nth-child(11):before,
.skin-2 .nav-list > li:nth-child(11):before {
  background-color: #9fc4e8;
}
.skin-2 .nav-list > li:nth-child(12) > a {
  color: #2ECEE7;
}
.skin-2 .nav-list > li:nth-child(12):hover > a,
.skin-2 .nav-list > li:nth-child(12).hover-show > a,
.skin-2 .nav-list > li.open:nth-child(12) > a {
  background-color: #4bb9ca;
}
.skin-2 .nav-list > li:nth-child(12) > .submenu {
  background-color: #304346;
}
.skin-2 .nav-list > li:nth-child(12) > .arrow:after,
.skin-2 .nav-list > li:nth-child(12) li > .arrow:after {
  border-right-color: #304346;
}
.skin-2 .nav-list > li.pull_up:nth-child(12) > .arrow:after {
  border-right-color: #304346 !important;
}
.skin-2 .nav-list > li:nth-child(12) > .submenu li:hover > a,
.skin-2 .nav-list > li:nth-child(12) > .submenu li.hover-show > a,
.skin-2 .nav-list > li:nth-child(12) > .submenu li.active > a,
.skin-2 .nav-list > li:nth-child(12) > .submenu li.open > a {
  color: #7ed6e3;
}
.skin-2 .nav-list > li.active:nth-child(12):before {
  background-color: #ce7da7;
  background-color: #78cad7;
}
.skin-2 .nav-list > li.active.highlight:nth-child(12):before,
.skin-2 .nav-list > li:nth-child(12):before {
  background-color: #a5dce4;
}
@media only screen and (min-width: 992px) {
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover:nth-child(1) > a {
    color: #00BE67;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover:nth-child(1) > .arrow:after {
    border-bottom-color: #2a4137;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover:nth-child(2) > a {
    color: #5ED02B;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover:nth-child(2) > .arrow:after {
    border-bottom-color: #36432f;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover:nth-child(3) > a {
    color: #A7D700;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover:nth-child(3) > .arrow:after {
    border-bottom-color: #3e442a;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover:nth-child(4) > a {
    color: #CEE100;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover:nth-child(4) > .arrow:after {
    border-bottom-color: #43452a;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover:nth-child(5) > a {
    color: #F8C600;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover:nth-child(5) > .arrow:after {
    border-bottom-color: #48422a;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover:nth-child(6) > a {
    color: #FFAB00;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover:nth-child(6) > .arrow:after {
    border-bottom-color: #493f2a;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover:nth-child(7) > a {
    color: #FF6E00;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover:nth-child(7) > .arrow:after {
    border-bottom-color: #49372a;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover:nth-child(8) > a {
    color: #F21C30;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover:nth-child(8) > .arrow:after {
    border-bottom-color: #472e30;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover:nth-child(9) > a {
    color: #DB348A;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover:nth-child(9) > .arrow:after {
    border-bottom-color: #45303b;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover:nth-child(10) > a {
    color: #C55EE7;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover:nth-child(10) > .arrow:after {
    border-bottom-color: #423646;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover:nth-child(11) > a {
    color: #218BEE;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover:nth-child(11) > .arrow:after {
    border-bottom-color: #2e3b47;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover:nth-child(12) > a {
    color: #2ECEE7;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover:nth-child(12) > .arrow:after {
    border-bottom-color: #304346;
  }
}
.skin-2 .nav-list > li.highlight.active:nth-child(1) > a {
  background-color: #18a665;
}
.skin-2 .nav-list > li.active.highlight:nth-child(1) > a:after {
  border-left-color: #18a665;
}
.skin-2 .nav-list > li.highlight.active:nth-child(2) > a {
  background-color: #6ab14a;
}
.skin-2 .nav-list > li.active.highlight:nth-child(2) > a:after {
  border-left-color: #6ab14a;
}
.skin-2 .nav-list > li.highlight.active:nth-child(3) > a {
  background-color: #98bc1b;
}
.skin-2 .nav-list > li.active.highlight:nth-child(3) > a:after {
  border-left-color: #98bc1b;
}
.skin-2 .nav-list > li.highlight.active:nth-child(4) > a {
  background-color: #b7c51c;
}
.skin-2 .nav-list > li.active.highlight:nth-child(4) > a:after {
  border-left-color: #b7c51c;
}
.skin-2 .nav-list > li.highlight.active:nth-child(5) > a {
  background-color: #d9b41f;
}
.skin-2 .nav-list > li.active.highlight:nth-child(5) > a:after {
  border-left-color: #d9b41f;
}
.skin-2 .nav-list > li.highlight.active:nth-child(6) > a {
  background-color: #dfa020;
}
.skin-2 .nav-list > li.active.highlight:nth-child(6) > a:after {
  border-left-color: #dfa020;
}
.skin-2 .nav-list > li.highlight.active:nth-child(7) > a {
  background-color: #df7220;
}
.skin-2 .nav-list > li.active.highlight:nth-child(7) > a:after {
  border-left-color: #df7220;
}
.skin-2 .nav-list > li.highlight.active:nth-child(8) > a {
  background-color: #d43a48;
}
.skin-2 .nav-list > li.active.highlight:nth-child(8) > a:after {
  border-left-color: #d43a48;
}
.skin-2 .nav-list > li.highlight.active:nth-child(9) > a {
  background-color: #bd5289;
}
.skin-2 .nav-list > li.active.highlight:nth-child(9) > a:after {
  border-left-color: #bd5289;
}
.skin-2 .nav-list > li.highlight.active:nth-child(10) > a {
  background-color: #b975d0;
}
.skin-2 .nav-list > li.active.highlight:nth-child(10) > a:after {
  border-left-color: #b975d0;
}
.skin-2 .nav-list > li.highlight.active:nth-child(11) > a {
  background-color: #3f8ad0;
}
.skin-2 .nav-list > li.active.highlight:nth-child(11) > a:after {
  border-left-color: #3f8ad0;
}
.skin-2 .nav-list > li.highlight.active:nth-child(12) > a {
  background-color: #4bb9ca;
}
.skin-2 .nav-list > li.active.highlight:nth-child(12) > a:after {
  border-left-color: #4bb9ca;
}
.skin-2 .sidebar-shortcuts .btn {
  background-color: #555 !important;
  border-radius: 32px;
  border-width: 2px;
}
.skin-2 .sidebar-shortcuts .btn:hover {
  background-color: #222 !important;
}
.skin-2 .sidebar-shortcuts .btn:focus {
  outline: none;
}
.skin-2 .sidebar-shortcuts-mini .btn {
  border-radius: 32px;
  border-width: 2px;
  opacity: 1;
  padding: 6px;
}
.skin-2 .sidebar-shortcuts,
.skin-2 .sidebar-shortcuts-mini {
  background-color: #393939;
}
.skin-2 .sidebar > .nav-search {
  background-color: #393939;
}
.skin-2 .sidebar-toggle {
  background-color: #2F2F2F;
  border-color: #767676;
}
.skin-2 .sidebar-toggle:before {
  border-color: inherit;
}
.skin-2 .sidebar-toggle > .ace-icon {
  background-color: #333;
  border-color: #AAA;
  color: #AAA;
}
.skin-2 .breadcrumbs {
  border-width: 0;
  background-color: #E7F2F8;
  border-radius: 4px;
  margin: 8px 8px 0;
}
@media only screen and (max-width: 991px) {
  .skin-2 .breadcrumbs {
    margin: 5px 5px 0;
  }
  .skin-2 .menu-toggler + .sidebar.responsive + .main-content .breadcrumbs {
    margin: 0;
    border-radius: 0;
  }
}
@media (min-width: 992px) {
  .skin-2 .breadcrumbs.breadcrumbs-fixed + .page-content {
    padding-top: 57px;
  }
}
@media (min-width: 992px) and (max-width: 991px) {
  .skin-2 .breadcrumbs.breadcrumbs-fixed + .page-content {
    padding-top: 54px;
  }
  .skin-2 .menu-toggler + .sidebar.reponsive + .main-content .breadcrumbs.breadcrumbs-fixed + .page-content {
    padding-top: 49px;
  }
}
@media (min-width: 992px) and (max-width: 991px) {
  .skin-2 .container.main-container .sidebar.compact + .main-content .breadcrumbs-fixed {
    width: 734px;
  }
}
@media (min-width: 992px) and (max-width: 991px) {
  .skin-2 .container.main-container .breadcrumbs-fixed {
    width: 734px;
  }
  .skin-2 .container.main-container .sidebar.menu-min + .main-content .breadcrumbs-fixed {
    width: 734px;
  }
  .skin-2 .container.main-container .sidebar.responsive-min + .main-content .breadcrumbs-fixed {
    width: 691px;
  }
}
@media (min-width: 992px) {
  .skin-2 .container.main-container .sidebar.compact + .main-content .breadcrumbs-fixed {
    width: 843px;
  }
}
@media (min-width: 992px) {
  .skin-2 .container.main-container .breadcrumbs-fixed {
    width: 964px;
  }
  .skin-2 .container.main-container .sidebar + .main-content .breadcrumbs-fixed {
    width: 758px;
  }
  .skin-2 .container.main-container .sidebar.menu-min + .main-content .breadcrumbs-fixed {
    width: 905px;
  }
}
@media (min-width: 1200px) {
  .skin-2 .container.main-container .sidebar.compact + .main-content .breadcrumbs-fixed {
    width: 1043px;
  }
}
@media (min-width: 1200px) {
  .skin-2 .container.main-container .breadcrumbs-fixed {
    width: 1148px;
  }
  .skin-2 .container.main-container .sidebar + .main-content .breadcrumbs-fixed {
    width: 958px;
  }
  .skin-2 .container.main-container .sidebar.menu-min + .main-content .breadcrumbs-fixed {
    width: 1105px;
  }
}
@media (min-width: 768px) {
  .skin-2 .container.main-container .sidebar.h-sidebar + .main-content .breadcrumbs-fixed {
    width: auto;
  }
}
@media only screen and (max-width: 991px) {
  .skin-2 .sidebar {
    border-width: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .skin-2 .nav-list li.active > a:after {
    display: none;
  }
}
.skin-2 .sidebar.menu-min .nav-list > li.open > a {
  color: #E6E6E6;
}
.skin-2 .sidebar.menu-min .nav-list > li.active > a {
  color: #FFF;
}
.skin-2 .sidebar.menu-min .nav-list > li.active > a:after {
  border-width: 9px 6px;
  top: 10px;
}
.skin-2 .sidebar.menu-min .nav-list > li.active.highlight > a:after {
  border-width: 20px 0 20px 10px;
  top: 0;
}
.skin-2 .sidebar.menu-min .nav-list > li > a > .menu-text {
  background-color: inherit;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  border-width: 0 0 0 1px;
  border-style: solid;
  border-color: #333;
  border-color: rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-left: 1px;
}
.skin-2 .sidebar.menu-min .nav-list > li.active > a > .menu-text {
  background-color: inherit;
  color: #FFF;
}
.skin-2 .sidebar.menu-min .nav-list > li > .submenu {
  border-style: solid;
  border-width: 1px 0 0 1px;
  border-color: #444;
  border-color: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-left: 1px;
}
.skin-2 .sidebar.menu-min .nav-list > li > .submenu:after {
  display: none;
}
.skin-2 .sidebar.menu-min .nav-list > li.active > .submenu {
  border-top-color: rgba(0, 0, 0, 0.5);
}
.skin-2 .sidebar.menu-min .sidebar-shortcuts-large {
  background-color: #393939;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #555555;
  border-width: 0 1px 1px 0;
  top: 0;
  border-color: #666;
}
.skin-2 .sidebar.menu-min .nav-list > li > .arrow:after,
.skin-2 .sidebar.menu-min .nav-list > li.active.highlight > .arrow:after {
  border-right-color: #bd5289;
}
.skin-2 .sidebar.menu-min .nav-list > li > .arrow:before,
.skin-2 .sidebar.menu-min .nav-list > li.active.highlight > .arrow:before {
  border-right-color: #333;
  border-right-color: rgba(0, 0, 0, 0.7);
}
.skin-2 .sidebar.menu-min .nav-list > li.active:not(.highlight) > a:not(.dropdown-toggle) > .menu-text {
  border-width: 1px;
  border-top-color: #555555;
  border-bottom-color: #555555;
  border-right-color: #555555;
}
.skin-2 .sidebar.menu-min .nav-list > li > a > .menu-text,
.skin-2 .sidebar.menu-min .nav-list > li.active.highlight > a > .menu-text {
  border-left-color: #333;
  border-left-color: rgba(0, 0, 0, 0.5);
}
.skin-2 .sidebar.menu-min .nav-list > li.pull_up > a > .menu-text,
.skin-2 .sidebar.menu-min .nav-list > li.pull_up.active.highlight > a > .menu-text {
  border-left-color: #222;
  border-left-color: rgba(0, 0, 0, 0.7);
}
.skin-2 .sidebar.menu-min .nav-list > li.pull_up.active:not(.highlight) > a > .menu-text {
  border-left-color: #666;
}
.skin-2 .sidebar.menu-min .nav-list > li:nth-child(1) > .arrow:after,
.skin-2 .sidebar.menu-min .nav-list > li.active.highlight:nth-child(1) > .arrow:after {
  border-right-color: #18a665;
}
.skin-2 .sidebar.menu-min .nav-list > li:nth-child(2) > .arrow:after,
.skin-2 .sidebar.menu-min .nav-list > li.active.highlight:nth-child(2) > .arrow:after {
  border-right-color: #6ab14a;
}
.skin-2 .sidebar.menu-min .nav-list > li:nth-child(3) > .arrow:after,
.skin-2 .sidebar.menu-min .nav-list > li.active.highlight:nth-child(3) > .arrow:after {
  border-right-color: #98bc1b;
}
.skin-2 .sidebar.menu-min .nav-list > li:nth-child(4) > .arrow:after,
.skin-2 .sidebar.menu-min .nav-list > li.active.highlight:nth-child(4) > .arrow:after {
  border-right-color: #b7c51c;
}
.skin-2 .sidebar.menu-min .nav-list > li:nth-child(5) > .arrow:after,
.skin-2 .sidebar.menu-min .nav-list > li.active.highlight:nth-child(5) > .arrow:after {
  border-right-color: #d9b41f;
}
.skin-2 .sidebar.menu-min .nav-list > li:nth-child(6) > .arrow:after,
.skin-2 .sidebar.menu-min .nav-list > li.active.highlight:nth-child(6) > .arrow:after {
  border-right-color: #dfa020;
}
.skin-2 .sidebar.menu-min .nav-list > li:nth-child(7) > .arrow:after,
.skin-2 .sidebar.menu-min .nav-list > li.active.highlight:nth-child(7) > .arrow:after {
  border-right-color: #df7220;
}
.skin-2 .sidebar.menu-min .nav-list > li:nth-child(8) > .arrow:after,
.skin-2 .sidebar.menu-min .nav-list > li.active.highlight:nth-child(8) > .arrow:after {
  border-right-color: #d43a48;
}
.skin-2 .sidebar.menu-min .nav-list > li:nth-child(9) > .arrow:after,
.skin-2 .sidebar.menu-min .nav-list > li.active.highlight:nth-child(9) > .arrow:after {
  border-right-color: #bd5289;
}
.skin-2 .sidebar.menu-min .nav-list > li:nth-child(10) > .arrow:after,
.skin-2 .sidebar.menu-min .nav-list > li.active.highlight:nth-child(10) > .arrow:after {
  border-right-color: #b975d0;
}
.skin-2 .sidebar.menu-min .nav-list > li:nth-child(11) > .arrow:after,
.skin-2 .sidebar.menu-min .nav-list > li.active.highlight:nth-child(11) > .arrow:after {
  border-right-color: #3f8ad0;
}
.skin-2 .sidebar.menu-min .nav-list > li:nth-child(12) > .arrow:after,
.skin-2 .sidebar.menu-min .nav-list > li.active.highlight:nth-child(12) > .arrow:after {
  border-right-color: #4bb9ca;
}
.skin-2 .sidebar.menu-min .nav-list > li.active > a > .menu-text {
  border-left-color: #777;
}
.skin-2 .sidebar.menu-min .nav-list > li.active > .arrow:after {
  border-right-color: #242424;
}
.skin-2 .sidebar.menu-min .nav-list > li.active > .arrow:before {
  border-right-color: #888;
}
@media (max-width: 991px) {
  .skin-2 .sidebar.responsive .nav-list > li.active > a:after {
    display: none;
  }
  .skin-2 .sidebar.responsive .nav-list > li.active.highlight > a:after {
    display: block;
  }
}
@media (max-width: 991px) {
  .skin-2 .sidebar.responsive-min .nav-list > li.open > a {
    color: #E6E6E6;
  }
  .skin-2 .sidebar.responsive-min .nav-list > li.active > a {
    color: #FFF;
  }
  .skin-2 .sidebar.responsive-min .nav-list > li.active > a:after {
    border-width: 9px 6px;
    top: 10px;
  }
  .skin-2 .sidebar.responsive-min .nav-list > li.active.highlight > a:after {
    border-width: 20px 0 20px 10px;
    top: 0;
  }
  .skin-2 .sidebar.responsive-min .nav-list > li > a > .menu-text {
    background-color: inherit;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.6);
    border-width: 0 0 0 1px;
    border-style: solid;
    border-color: #333;
    border-color: rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-left: 1px;
    height: 100px;
  }
  .skin-2 .sidebar.responsive-min .nav-list > li.active > a > .menu-text {
    background-color: inherit;
    color: #FFF;
  }
  .skin-2 .sidebar.responsive-min .nav-list > li > .submenu {
    border-style: solid;
    border-width: 1px 0 0 1px;
    border-color: #444;
    border-color: rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-left: 1px;
  }
  .skin-2 .sidebar.responsive-min .nav-list > li > .submenu:after {
    display: none;
  }
  .skin-2 .sidebar.responsive-min .nav-list > li.active > .submenu {
    border-top-color: rgba(0, 0, 0, 0.5);
  }
  .skin-2 .sidebar.responsive-min .sidebar-shortcuts-large {
    background-color: #393939;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #555555;
    border-width: 0 1px 1px 0;
    top: 0;
    border-color: #666;
  }
  .skin-2 .sidebar.responsive-min .nav-list > li > .arrow:after,
  .skin-2 .sidebar.responsive-min .nav-list > li.active.highlight > .arrow:after {
    border-right-color: #bd5289;
  }
  .skin-2 .sidebar.responsive-min .nav-list > li > .arrow:before,
  .skin-2 .sidebar.responsive-min .nav-list > li.active.highlight > .arrow:before {
    border-right-color: #333;
    border-right-color: rgba(0, 0, 0, 0.7);
  }
  .skin-2 .sidebar.responsive-min .nav-list > li.active:not(.highlight) > a:not(.dropdown-toggle) > .menu-text {
    border-width: 1px;
    border-top-color: #555555;
    border-bottom-color: #555555;
    border-right-color: #555555;
  }
  .skin-2 .sidebar.responsive-min .nav-list > li > a > .menu-text,
  .skin-2 .sidebar.responsive-min .nav-list > li.active.highlight > a > .menu-text {
    border-left-color: #333;
    border-left-color: rgba(0, 0, 0, 0.5);
  }
  .skin-2 .sidebar.responsive-min .nav-list > li.pull_up > a > .menu-text,
  .skin-2 .sidebar.responsive-min .nav-list > li.pull_up.active.highlight > a > .menu-text {
    border-left-color: #222;
    border-left-color: rgba(0, 0, 0, 0.7);
  }
  .skin-2 .sidebar.responsive-min .nav-list > li.pull_up.active:not(.highlight) > a > .menu-text {
    border-left-color: #666;
  }
  .skin-2 .sidebar.responsive-min .nav-list > li:nth-child(1) > .arrow:after,
  .skin-2 .sidebar.responsive-min .nav-list > li.active.highlight:nth-child(1) > .arrow:after {
    border-right-color: #18a665;
  }
  .skin-2 .sidebar.responsive-min .nav-list > li:nth-child(2) > .arrow:after,
  .skin-2 .sidebar.responsive-min .nav-list > li.active.highlight:nth-child(2) > .arrow:after {
    border-right-color: #6ab14a;
  }
  .skin-2 .sidebar.responsive-min .nav-list > li:nth-child(3) > .arrow:after,
  .skin-2 .sidebar.responsive-min .nav-list > li.active.highlight:nth-child(3) > .arrow:after {
    border-right-color: #98bc1b;
  }
  .skin-2 .sidebar.responsive-min .nav-list > li:nth-child(4) > .arrow:after,
  .skin-2 .sidebar.responsive-min .nav-list > li.active.highlight:nth-child(4) > .arrow:after {
    border-right-color: #b7c51c;
  }
  .skin-2 .sidebar.responsive-min .nav-list > li:nth-child(5) > .arrow:after,
  .skin-2 .sidebar.responsive-min .nav-list > li.active.highlight:nth-child(5) > .arrow:after {
    border-right-color: #d9b41f;
  }
  .skin-2 .sidebar.responsive-min .nav-list > li:nth-child(6) > .arrow:after,
  .skin-2 .sidebar.responsive-min .nav-list > li.active.highlight:nth-child(6) > .arrow:after {
    border-right-color: #dfa020;
  }
  .skin-2 .sidebar.responsive-min .nav-list > li:nth-child(7) > .arrow:after,
  .skin-2 .sidebar.responsive-min .nav-list > li.active.highlight:nth-child(7) > .arrow:after {
    border-right-color: #df7220;
  }
  .skin-2 .sidebar.responsive-min .nav-list > li:nth-child(8) > .arrow:after,
  .skin-2 .sidebar.responsive-min .nav-list > li.active.highlight:nth-child(8) > .arrow:after {
    border-right-color: #d43a48;
  }
  .skin-2 .sidebar.responsive-min .nav-list > li:nth-child(9) > .arrow:after,
  .skin-2 .sidebar.responsive-min .nav-list > li.active.highlight:nth-child(9) > .arrow:after {
    border-right-color: #bd5289;
  }
  .skin-2 .sidebar.responsive-min .nav-list > li:nth-child(10) > .arrow:after,
  .skin-2 .sidebar.responsive-min .nav-list > li.active.highlight:nth-child(10) > .arrow:after {
    border-right-color: #b975d0;
  }
  .skin-2 .sidebar.responsive-min .nav-list > li:nth-child(11) > .arrow:after,
  .skin-2 .sidebar.responsive-min .nav-list > li.active.highlight:nth-child(11) > .arrow:after {
    border-right-color: #3f8ad0;
  }
  .skin-2 .sidebar.responsive-min .nav-list > li:nth-child(12) > .arrow:after,
  .skin-2 .sidebar.responsive-min .nav-list > li.active.highlight:nth-child(12) > .arrow:after {
    border-right-color: #4bb9ca;
  }
  .skin-2 .sidebar.responsive-min .nav-list > li.active > a > .menu-text {
    border-left-color: #777;
  }
  .skin-2 .sidebar.responsive-min .nav-list > li.active > .arrow:after {
    border-right-color: #242424;
  }
  .skin-2 .sidebar.responsive-min .nav-list > li.active > .arrow:before {
    border-right-color: #888;
  }
  .skin-2 .sidebar.responsive-min .nav-list > li.active > a:after {
    display: block;
  }
}
@media only screen and (min-width: 992px) {
  .skin-2 .nav-list li.hover > .submenu {
    padding-left: 0;
    padding-right: 0;
    border: 1px solid;
    border-color: rgba(0, 0, 0, 0.4);
  }
  .skin-2 .nav-list li.hover > .submenu > li > a {
    margin-bottom: 0;
    margin-top: 0;
  }
  .skin-2 .nav-list li.hover > .submenu > li:first-child > a {
    border-top-width: 0;
  }
  .skin-2 .nav-list li .submenu > li.hover.active > a {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .skin-2 .nav-list > li > .submenu li.hover > .submenu {
    padding: 2px;
  }
  .skin-2 .nav-list > li > .submenu li > .submenu > li.hover > a {
    border-top-color: #222;
    border-top-color: rgba(255, 255, 255, 0.08);
  }
  .skin-2 .nav-list > li.active.hover:hover > a.dropdown-toggle:after,
  .skin-2 .nav-list > li.active.hover.hover-show > a.dropdown-toggle:after,
  .skin-2 .menu-min .nav-list > li.active:hover > a:after,
  .skin-2 .menu-min .nav-list > li.active.hover-show > a:after {
    display: none;
  }
}
@media only screen and (min-width: 992px) {
  .skin-2 .sidebar.navbar-collapse .nav-list > li.open.hover:not(:hover):not(:focus):not(.active) > a {
    background-color: #393939;
    font-weight: normal;
  }
  .skin-2 .sidebar.navbar-collapse .nav-list > li.open.hover:not(:hover):not(:focus):not(.active) > a > .menu-icon,
  .skin-2 .sidebar.navbar-collapse .nav-list > li.open.hover:not(:hover):not(:focus):not(.active) > a > .menu-text,
  .skin-2 .sidebar.navbar-collapse .nav-list > li.open.hover:not(:hover):not(:focus):not(.active) > a > .arrow {
    color: inherit;
  }
  .skin-2 .sidebar.navbar-collapse .nav-list > li > .submenu li.open.hover:not(:hover):not(:focus):not(.active) > a {
    color: #EEE;
  }
  .skin-2 .sidebar.navbar-collapse .nav-list > li > .submenu > li.open.hover:not(:hover):not(:focus):not(.active) > a > .menu-icon {
    display: none;
  }
}
@media only screen and (min-width: 992px) {
  .skin-2 .sidebar:not(.navbar-collapse) .nav-list > li .submenu > li.hover:hover > a,
  .skin-2 .sidebar:not(.navbar-collapse) .nav-list > li .submenu > li.hover.hover-show > a {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .skin-2 .sidebar:not(.navbar-collapse) .nav-list > li.active .submenu > li.hover:hover > a,
  .skin-2 .sidebar:not(.navbar-collapse) .nav-list > li.active .submenu > li.hover.hover-show > a {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
@media only screen and (max-width: 991px) {
  .skin-2 .sidebar.navbar-collapse .nav-list > li > .submenu:before,
  .skin-2 .sidebar.navbar-collapse .nav-list > li > .submenu > li:before,
  .skin-2 .sidebar.navbar-collapse .nav-list > li > .submenu > li > .submenu:before {
    display: none;
  }
  .skin-2 .sidebar.navbar-collapse .nav-list > li:before {
    height: 41px !important;
    top: 0;
  }
  .skin-2 .sidebar.navbar-collapse .nav-list > li > .submenu li > .submenu > li > a {
    margin-left: 0;
    padding-left: 42px;
  }
  .skin-2 .sidebar.navbar-collapse .nav-list > li > .submenu li > .submenu > li > .submenu > li > a {
    margin-left: 0;
    padding-left: 58px;
  }
  .skin-2 .sidebar.navbar-collapse .nav-list > li > a > .menu-text,
  .skin-2 .sidebar.navbar-collapse .nav-list > li.active.highlight > a > .menu-text {
    border-width: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 991px) {
  .skin-2 .sidebar.navbar-collapse .nav-list > li.hover > .submenu {
    padding-bottom: 0;
  }
  .skin-2 .sidebar.navbar-collapse .nav-list li.hover > .submenu {
    border-width: 0;
  }
  .skin-2 .sidebar.navbar-collapse .nav-list li li.hover:hover > a,
  .skin-2 .sidebar.navbar-collapse .nav-list li li.hover.hover-show > a {
    background-color: transparent;
  }
  .skin-2 .sidebar.navbar-collapse .nav-list li li.hover > a:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .skin-2 .sidebar.navbar-collapse .nav-list > li > .submenu > li.active.hover:hover > a,
  .skin-2 .sidebar.navbar-collapse .nav-list > li > .submenu > li.active.hover.hover-show > a {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .skin-2 .sidebar.navbar-collapse .nav-list > li > .submenu > li.active.hover > a:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .skin-2 .sidebar.navbar-collapse .nav-list > li > .submenu li.hover > .submenu {
    border-left-width: 0t;
    padding: 0;
  }
  .skin-2 .sidebar.navbar-collapse .nav-list > li > .submenu li.hover > .submenu > li > a {
    margin-left: 0 !important;
    padding-left: 37px !important;
  }
  .skin-2 .sidebar.navbar-collapse .nav-list > li > .submenu li > .submenu > li > .submenu > li > a {
    margin-left: 0 !important;
    padding-left: 51px !important;
  }
  .skin-2 .sidebar.navbar-collapse .nav-list li.hover > .submenu li > a {
    padding-left: 32px !important;
  }
  .skin-2 .sidebar.navbar-collapse .nav-list li.hover > .submenu li > a > .menu-icon {
    background-color: transparent;
  }
  .skin-2 .sidebar.navbar-collapse .nav-list li.hover > .submenu > li.active > a {
    background-color: transparent;
    font-weight: normal;
  }
  .skin-2 .sidebar.navbar-collapse .nav-list > li.hover > .submenu > li.active > a {
    background-color: rgba(0, 0, 0, 0.05);
    font-weight: bold;
  }
  .skin-2 .sidebar.navbar-collapse .nav-list li.hover > .submenu > li.active > a:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .skin-2 .sidebar.navbar-collapse .nav-list > li > .submenu li > .submenu > li.hover > a {
    border-top-width: 0;
  }
  .skin-2 .sidebar.navbar-collapse .nav-list > li .submenu > li.hover > a > .menu-icon {
    left: 12px;
  }
  .skin-2 .sidebar.navbar-collapse .nav-list > li .submenu > li.hover:hover > a,
  .skin-2 .sidebar.navbar-collapse .nav-list > li .submenu > li.hover.hover-show > a {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .skin-2 .sidebar.navbar-collapse .nav-list > li.active .submenu > li.hover:hover > a,
  .skin-2 .sidebar.navbar-collapse .nav-list > li.active .submenu > li.hover.hover-show > a {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .skin-2 .sidebar.navbar-collapse.menu-min .nav-list li.hover > .submenu li > a {
    padding-left: 32px;
  }
}
@media only screen and (max-width: 991px) {
  .skin-2 .sidebar.navbar-collapse.menu-min .sidebar-shortcuts-large {
    border-width: 0;
  }
  .skin-2 .sidebar.navbar-collapse.menu-min .nav-list > li > a > .menu-text {
    font-weight: normal;
    color: #E6E6E6;
    margin-left: 0;
  }
  .skin-2 .sidebar.navbar-collapse.menu-min .nav-list > li:hover > a > .menu-text {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.6);
  }
  .skin-2 .sidebar.navbar-collapse.menu-min .nav-list > li.active > a > .menu-text {
    font-weight: bold;
    color: #FFF;
  }
  .skin-2 .sidebar.navbar-collapse.menu-min .nav-list > li > .submenu {
    margin-left: 0;
    border-top-width: 0;
  }
  .skin-2 .sidebar.navbar-collapse.menu-min .nav-list > li > .submenu li > a > .menu-icon {
    left: 12px;
  }
}
@media (min-width: 992px) {
  .skin-2 .sidebar.compact .nav-list > li:before {
    height: auto;
    bottom: 0;
  }
}
.skin-2 .main-container .menu-toggler {
  background-color: #D685B0;
}
.skin-2 .main-container .menu-toggler:before {
  border-top-color: #FFF;
  border-bottom-color: #FFF;
}
.skin-2 .main-container .menu-toggler:after {
  border-top-color: #FFF;
  border-bottom-color: #FFF;
}
.skin-2 .main-container .menu-toggler > .toggler-text {
  border-top-color: #D685B0;
}
.skin-2 .navbar .navbar-toggle {
  background-color: #DB78A1;
}
.skin-2 .navbar .navbar-toggle:focus {
  background-color: #DB78A1;
  border-color: transparent;
}
.skin-2 .navbar .navbar-toggle:hover {
  background-color: #d76896;
  border-color: rgba(255, 255, 255, 0.1);
}
.skin-2 .navbar .navbar-toggle.display,
.skin-2 .navbar .navbar-toggle[data-toggle=collapse]:not(.collapsed) {
  background-color: #d2588b;
  box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.25);
  border-color: rgba(255, 255, 255, 0.35);
}
@media only screen and (min-width: 768px) {
  .skin-2 .container.main-container:before {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
  }
}
@media only screen and (min-width: 992px) {
  .skin-2 .sidebar.h-sidebar {
    background-color: #393939;
  }
  .skin-2 .sidebar.h-sidebar:before {
    background-color: #767676;
    border-bottom-color: #7b7b7b;
  }
  .skin-2 .sidebar.h-sidebar .nav-list {
    border-bottom-width: 0;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li > a > .menu-text {
    color: #E6E6E6;
    font-weight: normal;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li:hover > a > .menu-text,
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover-show > a > .menu-text {
    color: #FFF;
    font-weight: normal;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.active > a > .menu-text {
    font-weight: bold;
    color: #FFF;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li:before {
    left: 0;
    right: 0;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover > .submenu {
    margin-left: -1px;
    border-width: 1px;
    border-top-color: rgba(0, 0, 0, 0.4);
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li .submenu > li.hover > a {
    padding-left: 22px;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li .submenu > li.hover > a > .menu-icon {
    left: 4px;
  }
  .skin-2 .sidebar.h-sidebar.compact .nav-list > li.active:before {
    height: 2px;
    bottom: auto;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.highlight.active > a:after {
    display: none;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover.active > a:after {
    display: block;
    border-color: transparent transparent #FFF;
    border-width: 8px;
    top: auto;
    bottom: 0;
    right: auto;
    left: 50%;
    margin-left: -8px;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover.active:hover > a:after,
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover.active.hover-show > a:after {
    display: none;
  }
  .skin-2 .sidebar.h-sidebar .sidebar-shortcuts-large {
    background-color: #505050;
    border-width: 1px 0 0 !important;
    border-top: 1px solid #777;
    top: 100%;
  }
  .skin-2 .sidebar.h-sidebar .sidebar-shortcuts-mini {
    padding-top: 2px;
  }
  .skin-2 .sidebar.h-sidebar .sidebar-shortcuts-mini > .btn {
    padding: 7px;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.active.highlight > .arrow:after {
    border-right-color: transparent;
    border-bottom-color: #bd5289;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.active.highlight > .arrow:before {
    border-right-color: transparent;
    border-bottom-color: #191919;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover > .arrow:after {
    border-right-color: transparent;
    border-bottom-color: #45303b;
  }
  .skin-2 .sidebar.h-sidebar .nav-list > li.hover > .arrow:before {
    border-right-color: transparent;
    border-bottom-color: #191919;
  }
  .skin-2 .sidebar.h-sidebar .sidebar-shortcuts-large:after {
    border-bottom-color: #505050;
  }
  .skin-2 .sidebar.h-sidebar .sidebar-shortcuts-large:before {
    border-bottom-color: #999;
  }
  .skin-2 .sidebar.h-sidebar.menu-min .sidebar-shortcuts-mini {
    padding-top: 3px;
  }
  .skin-2 .sidebar.h-sidebar.menu-min .sidebar-shortcuts-mini > .btn {
    padding: 6px;
  }
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li > a > .menu-text {
    border-width: 1px 0 0 0;
    border-top-color: rgba(0, 0, 0, 0.5);
  }
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li > .arrow:before,
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li.active.highlight > .arrow:before {
    border-bottom-color: rgba(0, 0, 0, 0.7);
  }
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li:nth-child(1) > .arrow:after,
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li.active.highlight:nth-child(1) > .arrow:after {
    border-bottom-color: #18a665;
  }
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li:nth-child(2) > .arrow:after,
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li.active.highlight:nth-child(2) > .arrow:after {
    border-bottom-color: #6ab14a;
  }
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li:nth-child(3) > .arrow:after,
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li.active.highlight:nth-child(3) > .arrow:after {
    border-bottom-color: #98bc1b;
  }
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li:nth-child(4) > .arrow:after,
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li.active.highlight:nth-child(4) > .arrow:after {
    border-bottom-color: #b7c51c;
  }
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li:nth-child(5) > .arrow:after,
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li.active.highlight:nth-child(5) > .arrow:after {
    border-bottom-color: #d9b41f;
  }
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li:nth-child(6) > .arrow:after,
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li.active.highlight:nth-child(6) > .arrow:after {
    border-bottom-color: #dfa020;
  }
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li:nth-child(7) > .arrow:after,
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li.active.highlight:nth-child(7) > .arrow:after {
    border-bottom-color: #df7220;
  }
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li:nth-child(8) > .arrow:after,
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li.active.highlight:nth-child(8) > .arrow:after {
    border-bottom-color: #d43a48;
  }
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li:nth-child(9) > .arrow:after,
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li.active.highlight:nth-child(9) > .arrow:after {
    border-bottom-color: #bd5289;
  }
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li:nth-child(10) > .arrow:after,
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li.active.highlight:nth-child(10) > .arrow:after {
    border-bottom-color: #b975d0;
  }
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li:nth-child(11) > .arrow:after,
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li.active.highlight:nth-child(11) > .arrow:after {
    border-bottom-color: #3f8ad0;
  }
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li:nth-child(12) > .arrow:after,
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li.active.highlight:nth-child(12) > .arrow:after {
    border-bottom-color: #4bb9ca;
  }
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li.active > .arrow:after {
    border-bottom-color: #242424;
  }
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li.active > .arrow:before {
    border-bottom-color: #888;
  }
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li > a > .menu-text,
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li.active.highlight > a > .menu-text {
    border-top-color: #333;
    border-top-color: rgba(0, 0, 0, 0.5);
  }
  .skin-2 .sidebar.h-sidebar.menu-min .nav-list > li.active > a > .menu-text {
    border-top-color: #777;
  }
  .skin-2 .sidebar.h-sidebar + .main-content .breadcrumbs {
    border-color: #d5e7f1;
    top: 2px;
  }
  .skin-2 .h-sidebar.sidebar-fixed + .main-content {
    padding-top: 86px;
  }
  .skin-2 .h-sidebar.sidebar-fixed.no-gap + .main-content {
    padding-top: 72px;
  }
  .skin-2 .h-sidebar.sidebar-fixed.menu-min + .main-content {
    padding-top: 61px;
  }
  .skin-2 .h-sidebar.sidebar-fixed.menu-min.no-gap + .main-content {
    padding-top: 47px;
  }
  .skin-2 .main-content .h-sidebar.sidebar .nav-list {
    border-left: 1px solid #575757;
  }
}
.skin-2 .sidebar-scroll .sidebar-shortcuts {
  border-bottom-color: #555555;
}
.skin-2 .sidebar-scroll .sidebar-toggle {
  border-top-color: #555555;
}
.skin-2 .scrollout .scroll-track {
  background-color: transparent;
}
.skin-2 .scrollout .scroll-bar {
  background-color: #CCC;
  background-color: rgba(0, 0, 0, 0.2);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .skin-2 .navbar.navbar-collapse {
    background-color: transparent;
  }
  .skin-2 .navbar.navbar-collapse:before,
  .skin-2 .navbar.navbar-collapse .navbar-container {
    background: #C6487E;
  }
}
.skin-2 .nav-list > li.disabled:before {
  display: none !important;
}
.skin-2 .nav-list > li.disabled > a,
.skin-2 .nav-list > li > a.disabled {
  background-color: #555 !important;
  color: #CCC !important;
}
.skin-2 .nav-list > li.disabled > a > .menu-text,
.skin-2 .nav-list > li > a.disabled > .menu-text {
  color: #CCC !important;
}
.skin-2 .nav-list > li.disabled > a .label,
.skin-2 .nav-list > li > a.disabled .label,
.skin-2 .nav-list > li.disabled > a .badge,
.skin-2 .nav-list > li > a.disabled .badge {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.skin-2 .nav-list > li.active.highlight.disabled > a:after,
.skin-2 .nav-list > li.active.highlight > a.disabled:after {
  border-left-color: #555 !important;
}
.skin-2 .nav-list li .submenu > li.disabled > a,
.skin-2 .nav-list li .submenu > li > a.disabled,
.skin-2 .nav-list li.disabled .submenu > li > a,
.skin-2 .nav-list li > a.disabled + .submenu > li > a {
  background-color: #666 !important;
  color: #DDD !important;
  cursor: not-allowed !important;
}
.skin-2 .nav-list li .submenu > li.disabled > a > .menu-icon,
.skin-2 .nav-list li .submenu > li > a.disabled > .menu-icon,
.skin-2 .nav-list li.disabled .submenu > li > a > .menu-icon,
.skin-2 .nav-list li > a.disabled + .submenu > li > a > .menu-icon {
  display: none;
}
/* skin 3 */
.skin-3 {
  background-color: #BBB;
}
.skin-3 .main-container:before {
  background: #FFF;
  background: -moz-linear-gradient(top, #EEF5FA 0%, #FFF 8%) 0 4px;
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#EEF5FA), color-stop(4%, #FFF)) 0 4px;
  background: -webkit-linear-gradient(top, #EEF5FA 0%, #FFF 8%) 0 4px;
  background: -o-linear-gradient(top, #EEF5FA 0%, #FFF 8%) 0 4px;
  background: -ms-linear-gradient(top, #EEF5FA 0%, #FFF 8%) 0 4px;
  background: linear-gradient(top, #EEF5FA 0%, #FFF 8%) 0 4px;
  -moz-background-size: 100% 26px;
  -webkit-background-size: 100% 26px;
  -o-background-size: 100% 26px;
  -ms-background-size: 100% 26px;
  background-size: 100% 26px;
}
@media (min-width: 768px) {
  .skin-3 .main-container.container:before {
    -webkit-box-shadow: 0 0 0 1px rgba(50, 100, 200, 0.1);
    box-shadow: 0 0 0 1px rgba(50, 100, 200, 0.1);
  }
}
.skin-3 .navbar {
  background: #404040;
}
.skin-3 .sidebar {
  background-color: #DBDBDB;
  border-style: solid;
  border-width: 0 1px 0 0;
  border-color: #A4C6DD;
}
.skin-3 .nav-list > li {
  border-color: #F2F2F2;
}
.skin-3 .nav-list > li > a {
  background-color: #E3E3E3;
  color: #5A5A5A;
}
.skin-3 .nav-list > li > a > .arrow {
  color: inherit;
}
.skin-3 .nav-list > li > a:focus {
  background-color: #e8e8e8;
  color: #5A5A5A;
}
.skin-3 .nav-list > li:hover {
  border-color: #95bad2 !important;
}
.skin-3 .nav-list > li:hover + li {
  border-top-color: #95bad2 !important;
}
.skin-3 .nav-list > li.open {
  border-color: #95bad2 !important;
}
.skin-3 .nav-list > li.open + li {
  border-top-color: #95bad2 !important;
}
.skin-3 .nav-list > li.active {
  border-color: #A4C6DD !important;
}
.skin-3 .nav-list > li.active + li {
  border-color: #A4C6DD !important;
}
.skin-3 .nav-list > li.active + li:last-child {
  border-bottom-color: #F2F2F2 !important;
}
.skin-3 .nav-list > li.active + li:last-child:hover {
  border-bottom-color: #95bad2 !important;
}
.skin-3 .nav-list > li:hover > a {
  background-color: #FFF;
  color: #337dbb;
}
.skin-3 .nav-list > li.open > a,
.skin-3 .nav-list > li.open:hover > a {
  color: #337dbb;
  background-color: #F8F8F8;
}
.skin-3 .nav-list > li.open > a > .arrow {
  color: inherit;
}
.skin-3 .nav-list > li.active > a,
.skin-3 .nav-list > li.active.highlight > a {
  background-color: #f3faff !important;
  color: #4D96CB !important;
}
.skin-3 .nav-list > li:hover:before,
.skin-3 .nav-list > li.open:before {
  display: block;
  background-color: #4f8ab4 !important;
}
.skin-3 .nav-list > li.active:before {
  display: block;
  background-color: #4D96CB !important;
}
.skin-3 .page-content {
  background-color: transparent;
}
.skin-3 .infobox-container .infobox:not(.infobox-dark) {
  border-style: solid;
  background-color: transparent;
}
.skin-3 .nav-list > li.active:after {
  display: none;
}
.skin-3 .nav-list li.active > a:after {
  border-right-color: #FFF;
  border-width: 12px 8px;
  top: 7px;
  right: -1px;
}
.skin-3 .nav-list li.active > a:before {
  display: block;
  content: "";
  position: absolute;
  background-color: transparent;
  border-style: solid;
  border-color: transparent;
  border-right-color: #91bad6;
  right: 0;
  border-width: 12px 8px;
  top: 7px;
}
.skin-3 .nav-list > li.active > .submenu li.active > a:before,
.skin-3 .nav-list > li.active > .submenu li.active > a:after {
  top: 4px;
}
.skin-3 .nav-list li.active.open > a:before {
  display: none;
}
.skin-3 .nav-list li.highlight.active.open > a:before {
  display: block;
}
.skin-3 .nav-list li.active:not(.open) li.active > a:before {
  display: none !important;
}
.skin-3 .nav-list > li.highlight.active > a:after {
  border-left-color: #f3faff;
}
.skin-3 .nav-list > li.highlight.active > a:before {
  border-left-color: #91bad6;
}
.skin-3 .nav-list li > .arrow:before {
  border-right-color: #7fafcf;
}
.skin-3 .nav-list > li .submenu > li.active:not(.open) > a {
  background-color: #F5F7FA;
}
.skin-3 .nav-list > li .submenu > li.active:not(.open) > a:hover {
  background-color: #F1F5F9;
}
@media only screen and (max-width: 991px) {
  .skin-3 .sidebar.responsive .nav-list > li.active.open > a:after {
    display: none;
  }
}
@media only screen and (min-width: 992px) {
  .skin-3 .nav-list li.hover > .submenu {
    border-color: #99bfd9;
  }
}
@media only screen and (min-width: 992px) {
  .skin-3 .nav-list li.hover.active > a:before {
    display: block;
  }
  .skin-3 .nav-list li.hover .submenu > li.active > a:before {
    display: none ;
  }
}
.skin-3 .sidebar.menu-min .nav-list > li > a > .menu-text {
  background-color: #f1f5f9;
  border-color: #A4C6DD;
}
.skin-3 .sidebar.menu-min .nav-list > li.active > a > .menu-text {
  background-color: #EDF3F7;
}
.skin-3 .sidebar.menu-min .nav-list > li > .submenu {
  border-color: #A4C6DD;
  border-top-color: #C9DAE5;
}
.skin-3 .sidebar.menu-min .nav-list > li.active > .arrow:before {
  border-right-color: #709FBF;
}
.skin-3 .sidebar.menu-min .nav-list > li > .arrow:after {
  border-right-color: #EDF3F7;
}
.skin-3 .sidebar.menu-min .nav-list li.active > a:after,
.skin-3 .sidebar.menu-min .nav-list li.active > a:before {
  display: none;
}
.skin-3 .sidebar.menu-min .nav-list > li.active > a:after,
.skin-3 .sidebar.menu-min .nav-list > li.active > a:before {
  display: block;
  border-width: 9px 7px;
  top: 10px;
}
.skin-3 .sidebar.menu-min .nav-list > li.active.highlight > a:after,
.skin-3 .sidebar.menu-min .nav-list > li.active.highlight > a:before {
  border-width: 20px 0 21px 10px;
  top: -1px;
}
.skin-3 .sidebar.menu-min .nav-list > li.active:hover > a:after,
.skin-3 .sidebar.menu-min .nav-list > li.active.hover-show > a:after,
.skin-3 .sidebar.menu-min .nav-list > li.active:hover > a:before,
.skin-3 .sidebar.menu-min .nav-list > li.active.hover-show > a:before {
  display: none;
}
.skin-3 .sidebar.menu-min .sidebar-shortcuts-large {
  background-color: #F5F5F5;
}
@media only screen and (max-width: 991px) {
  .skin-3 .sidebar.menu-min.responsive .nav-list > li.active > a:after,
  .skin-3 .sidebar.menu-min.responsive .nav-list > li.active > a:before {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  .skin-3 .sidebar.responsive {
    border-bottom-width: 1px !important;
  }
  .skin-3 .sidebar.responsive .nav-list > li.active.open > a:after {
    display: none;
  }
  .skin-3 .sidebar.responsive .nav-list > li.active.highlight > a:after,
  .skin-3 .sidebar.responsive .nav-list > li.active.highlight > a:before {
    display: block;
  }
  .skin-3 .sidebar.navbar-collapse {
    border-bottom-color: #A4C6DD;
  }
  .skin-3 .nav-list li.active > a:after,
  .skin-3 .nav-list li.active > a:before {
    display: none;
  }
  .skin-3 .sidebar.responsive-min .nav-list > li > a > .menu-text {
    background-color: #f1f5f9;
    border-color: #A4C6DD;
  }
  .skin-3 .sidebar.responsive-min .nav-list > li.active > a > .menu-text {
    background-color: #EDF3F7;
  }
  .skin-3 .sidebar.responsive-min .nav-list > li > .submenu {
    border-color: #A4C6DD;
    border-top-color: #C9DAE5;
  }
  .skin-3 .sidebar.responsive-min .nav-list > li.active > .arrow:before {
    border-right-color: #709FBF;
  }
  .skin-3 .sidebar.responsive-min .nav-list > li > .arrow:after {
    border-right-color: #EDF3F7;
  }
  .skin-3 .sidebar.responsive-min .nav-list li.active > a:after,
  .skin-3 .sidebar.responsive-min .nav-list li.active > a:before {
    display: none;
  }
  .skin-3 .sidebar.responsive-min .nav-list > li.active > a:after,
  .skin-3 .sidebar.responsive-min .nav-list > li.active > a:before {
    display: block;
    border-width: 9px 7px;
    top: 10px;
  }
  .skin-3 .sidebar.responsive-min .nav-list > li.active.highlight > a:after,
  .skin-3 .sidebar.responsive-min .nav-list > li.active.highlight > a:before {
    border-width: 20px 0 21px 10px;
    top: -1px;
  }
  .skin-3 .sidebar.responsive-min .nav-list > li.active:hover > a:after,
  .skin-3 .sidebar.responsive-min .nav-list > li.active.hover-show > a:after,
  .skin-3 .sidebar.responsive-min .nav-list > li.active:hover > a:before,
  .skin-3 .sidebar.responsive-min .nav-list > li.active.hover-show > a:before {
    display: none;
  }
  .skin-3 .sidebar.responsive-min .sidebar-shortcuts-large {
    background-color: #F5F5F5;
  }
  .skin-3 .sidebar.responsive-max {
    border-width: 0 1px 1px 0;
  }
  .skin-3 .sidebar.responsive-max .nav-list li.hover.active > a:before {
    display: none;
  }
  .skin-3 .sidebar.responsive-max .nav-list > li.active.open > a:after {
    display: none;
  }
  .skin-3 .sidebar.responsive-max .nav-list > li.active.highlight > a:after,
  .skin-3 .sidebar.responsive-max .nav-list > li.active.highlight > a:before {
    display: block;
  }
  .skin-3 .sidebar.navbar-collapse .sidebar-shortcuts-large {
    background-color: transparent;
  }
}
.skin-3 .sidebar-shortcuts,
.skin-3 .sidebar-shortcuts-mini {
  background-color: #DBDBDB;
}
.skin-3 .sidebar-shortcuts-large > .btn:focus {
  outline: none;
}
.skin-3 .sidebar > .nav-search {
  background-color: #DBDBDB;
}
.skin-3 .sidebar-toggle {
  background-color: #D0D0D0;
  border-color: #F2F2F2;
}
.skin-3 .sidebar-toggle:before {
  border-color: #F2F2F2;
}
.skin-3 .sidebar-toggle > .ace-icon {
  background-color: #FFF;
  border-color: #999;
  color: #999;
}
.skin-3 .sidebar-scroll .nav-wrap-up + .sidebar-toggle:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: -1px;
  border-right: 1px solid #A4C6DD;
}
@media only screen and (max-width: 991px) {
  .skin-3 .sidebar.navbar-collapse .nav-list > li:before {
    height: 43px !important;
  }
  .skin-3 .sidebar.navbar-collapse .sidebar-shortcuts {
    padding: 0 0 3px !important;
  }
}
@media only screen and (min-width: 992px) {
  .skin-3 .nav-list > li.active.hover:hover > a.dropdown-toggle:after,
  .skin-3 .nav-list > li.active.hover.hover-show > a.dropdown-toggle:after,
  .skin-3 .nav-list > li.active.hover:hover > a.dropdown-toggle:before,
  .skin-3 .nav-list > li.active.hover.hover-show > a.dropdown-toggle:before {
    display: none;
  }
}
.skin-3 .main-container .menu-toggler {
  background-color: #62A8D1;
  color: #FFF;
}
.skin-3 .main-container .menu-toggler:before,
.skin-3 .main-container .menu-toggler:after {
  border-color: #FFF;
}
.skin-3 .main-container .menu-toggler > .toggler-text {
  border-top-color: #62A8D1;
}
.skin-3 .main-container .menu-toggler > .toggler-text:after {
  color: #FFF;
}
.skin-3 .navbar .navbar-toggle {
  border-color: rgba(255, 255, 255, 0.15);
  transition: background-color 0.1s ease 0s;
  background-color: #648CAE;
}
.skin-3 .navbar .navbar-toggle:focus {
  background-color: #648CAE;
  border-color: rgba(255, 255, 255, 0.15);
}
.skin-3 .navbar .navbar-toggle:hover {
  background-color: #5782a7;
  border-color: rgba(255, 255, 255, 0.15);
}
.skin-3 .navbar .navbar-toggle.display,
.skin-3 .navbar .navbar-toggle[data-toggle=collapse]:not(.collapsed) {
  background-color: #507899;
  border-color: rgba(255, 255, 255, 0.3);
}
.skin-3 .breadcrumbs {
  border-width: 0;
  background-color: #E7F2F8;
  border-radius: 4px;
  margin: 8px 8px 0;
}
@media only screen and (max-width: 991px) {
  .skin-3 .breadcrumbs {
    margin: 5px 5px 0;
  }
  .skin-3 .menu-toggler + .sidebar.responsive + .main-content .breadcrumbs {
    margin: 0;
    border-radius: 0;
  }
}
@media (min-width: 992px) {
  .skin-3 .breadcrumbs.breadcrumbs-fixed + .page-content {
    padding-top: 57px;
  }
}
@media (min-width: 992px) and (max-width: 991px) {
  .skin-3 .breadcrumbs.breadcrumbs-fixed + .page-content {
    padding-top: 54px;
  }
  .skin-3 .menu-toggler + .sidebar.reponsive + .main-content .breadcrumbs.breadcrumbs-fixed + .page-content {
    padding-top: 49px;
  }
}
@media (min-width: 992px) and (max-width: 991px) {
  .skin-3 .container.main-container .sidebar.compact + .main-content .breadcrumbs-fixed {
    width: 734px;
  }
}
@media (min-width: 992px) and (max-width: 991px) {
  .skin-3 .container.main-container .breadcrumbs-fixed {
    width: 734px;
  }
  .skin-3 .container.main-container .sidebar.menu-min + .main-content .breadcrumbs-fixed {
    width: 734px;
  }
  .skin-3 .container.main-container .sidebar.responsive-min + .main-content .breadcrumbs-fixed {
    width: 691px;
  }
}
@media (min-width: 992px) {
  .skin-3 .container.main-container .sidebar.compact + .main-content .breadcrumbs-fixed {
    width: 843px;
  }
}
@media (min-width: 992px) {
  .skin-3 .container.main-container .breadcrumbs-fixed {
    width: 964px;
  }
  .skin-3 .container.main-container .sidebar + .main-content .breadcrumbs-fixed {
    width: 758px;
  }
  .skin-3 .container.main-container .sidebar.menu-min + .main-content .breadcrumbs-fixed {
    width: 905px;
  }
}
@media (min-width: 1200px) {
  .skin-3 .container.main-container .sidebar.compact + .main-content .breadcrumbs-fixed {
    width: 1043px;
  }
}
@media (min-width: 1200px) {
  .skin-3 .container.main-container .breadcrumbs-fixed {
    width: 1148px;
  }
  .skin-3 .container.main-container .sidebar + .main-content .breadcrumbs-fixed {
    width: 958px;
  }
  .skin-3 .container.main-container .sidebar.menu-min + .main-content .breadcrumbs-fixed {
    width: 1105px;
  }
}
@media only screen and (max-width: 991px) {
  .skin-3 .nav-list li.active > a:before,
  .skin-3 .nav-list li.active > a:after {
    display: none;
  }
}
.skin-3 .sidebar-shortcuts-large > .btn {
  line-height: 26px;
  border-width: 1px;
}
.skin-3 .sidebar-shortcuts-mini {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 1px;
}
.skin-3 .sidebar-shortcuts-mini > .btn {
  border-width: 1px;
  opacity: 1;
  padding: 7px;
  margin: 1px 1px 0 0;
}
@media only screen and (min-width: 992px) {
  .skin-3 .sidebar.h-sidebar {
    background-color: #E3E3E3;
  }
  .skin-3 .sidebar.h-sidebar:before {
    background-color: #CBD0D6;
    border-bottom-width: 0;
  }
  .skin-3 .sidebar.h-sidebar .sidebar-shortcuts-mini > .btn {
    padding: 8px;
  }
  .skin-3 .sidebar.h-sidebar .sidebar-shortcuts-large {
    background-color: #FFF;
    line-height: 36px;
  }
  .skin-3 .sidebar.h-sidebar + .main-content .breadcrumbs {
    border-color: #d5e7f1;
    top: 2px;
  }
  .skin-3 .sidebar.h-sidebar .nav-list > li.hover > .submenu {
    border-color: #A4C6DD;
  }
  .skin-3 .sidebar.h-sidebar .nav-list > li {
    border-color: #F2F2F2;
  }
  .skin-3 .sidebar.h-sidebar .nav-list > li:hover,
  .skin-3 .sidebar.h-sidebar .nav-list > li:hover + li {
    border-left-color: #95bad2;
  }
  .skin-3 .sidebar.h-sidebar .nav-list > li:last-child:hover {
    border-right-color: #95bad2;
  }
  .skin-3 .sidebar.h-sidebar .nav-list > li.active,
  .skin-3 .sidebar.h-sidebar .nav-list > li.active + li,
  .skin-3 .sidebar.h-sidebar .nav-list > li:hover + li.active {
    border-left-color: #A4C6DD;
  }
  .skin-3 .sidebar.h-sidebar .nav-list > li.active:last-child {
    border-right-color: #A4C6DD;
  }
  .skin-3 .sidebar.h-sidebar .nav-list > li.active > a:after {
    left: 0;
    top: auto;
    right: auto;
    bottom: -2px;
    left: 50%;
    margin-left: -7px;
    border-color: transparent;
    border-width: 8px 7px !important;
    border-bottom-color: #FFF;
  }
  .skin-3 .sidebar.h-sidebar .nav-list > li.active > a:before {
    display: block;
    left: 0;
    top: auto;
    right: auto;
    bottom: -1px;
    left: 50%;
    margin-left: -8px !important;
    border-width: 8px !important;
    border-color: transparent;
    border-bottom-color: #74add7;
  }
  .skin-3 .sidebar.h-sidebar .nav-list > li.hover > .arrow:before {
    border-right-color: transparent;
    border-bottom-color: #7fafcf;
  }
  .skin-3 .sidebar.h-sidebar.menu-min .sidebar-shortcuts {
    max-width: 52px;
    padding-left: 2px;
    padding-right: 2px;
  }
  .skin-3 .sidebar.h-sidebar.menu-min .sidebar-shortcuts-mini > .btn {
    padding: 7px;
  }
  .skin-3 .sidebar.h-sidebar.menu-min .nav-list > li.hover > .submenu {
    border-top-color: #C9DAE5;
  }
  .skin-3 .sidebar.h-sidebar.menu-min .nav-list > li.active > a > .menu-text {
    border-left-color: #A4C6DD;
  }
  .skin-3 .sidebar.h-sidebar.menu-min .nav-list > li > .arrow:after {
    border-bottom-color: #EDF3F7;
  }
}
.skin-3 .sidebar-scroll .sidebar-shortcuts {
  border-bottom-color: 1px solid #99B6C9;
}
.skin-3 .sidebar-scroll .sidebar-toggle {
  border-top-color: #99B6C9;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .skin-3 .navbar.navbar-collapse {
    background-color: transparent;
  }
  .skin-3 .navbar.navbar-collapse:before,
  .skin-3 .navbar.navbar-collapse .navbar-container {
    background: #404040;
  }
}
.skin-3 .nav-list > li.disabled:before {
  display: none !important;
}
