/* a few ie8/9 specific changes */
.navbar .navbar-inner , .navbar .btn-navbar {
	filter:progid:DXImageTransform.Microsoft.gradient(enabled=false) !important;
}
.dropdown-menu li > a,
.dropdown-submenu > a  {
	filter:progid:DXImageTransform.Microsoft.gradient(enabled=false) !important;
}
.btn {
	filter:progid:DXImageTransform.Microsoft.gradient(enabled=false) !important;
}
.progress , .progress .bar {
	filter:progid:DXImageTransform.Microsoft.gradient(enabled=false) !important;
}




#cboxLoadingGraphic > .ace-icon, #cboxLoadingGraphic > .fa , #cboxLoadingGraphic > .glyphicon {
 display:inline-block;
 background:#FFF url('images/loading.gif') no-repeat center; 
}
#cboxLoadingGraphic > .ace-icon:before, #cboxLoadingGraphic > .fa:before, #cboxLoadingGraphic > .glyphicon:before {
 display:none;
}

.widget-box-overlay > .loading-icon {
  display:inline-block;
  width:24px;
  height:24px;
  background:transparent url('images/loading.gif') no-repeat center; 
}
.widget-box-overlay > .loading-icon:before {
  display:none;
}


@media (max-width: 991px) {
 .sidebar.responsive {
   display: none;
 }
 .sidebar.responsive.display {
   display: block;
 }
}

.profile-user-info { width: 98%; }
.wysiwyg-toolbar .dropdown-menu { min-width: 260px; }

.modal.modal-contained {
  position: absolute !important;
}


/* IE9 and below have an issue with left & right borders and RTL */
.rtl .sidebar .nav-list li.active > a:before, .rtl .sidebar .nav-list li.active > a:after {
 direction: ltr;
 text-align: left;
}
.rtl .sidebar .nav-list li > .arrow {
 direction: ltr;
 text-align: left;
}




/* following is for ie8 only */
@media \0screen {

html {
 /* to fix it when inside iframe */
 position: absolute;
 width: 100%;
}

body {
 background-color: #E9E9E9;
 min-height: 100%;
}

.sidebar-fixed:before {
 left: 0;
}
/* it doesn't show up in ie8, so let's make it visible at least when inside .container */
.sidebar .nav-list > li:before {
	z-index: 4444;
	left: -3px;
}



.nav-list > li > .submenu li.open > a > .menu-icon {
  display: inline-block;
}

.ace-nav > li.white-opaque {
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#CCFFFFFF', endColorstr='#CCFFFFFF',GradientType=0 );
}
.ace-nav > li.dark-opaque {
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33000000', endColorstr='#33000000',GradientType=0 );
}

.ace-nav > li.transparent > a:hover,
.ace-nav > li.transparent > a:focus, 
.ace-nav > li.open.transparent > a {
	background-color: transparent !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#19000000', endColorstr='#19000000',GradientType=0 );
}

.ace-nav > li.light-10 > a {
	background-color: transparent !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#19FFFFFF', endColorstr='#19FFFFFF',GradientType=0 );
}
.ace-nav > li.light-10 > a:hover, .ace-nav > li.light-10 > a:focus, .ace-nav > li.open.light-10 > a {
	background-color: transparent !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#CCFFFFFF', endColorstr='#CCFFFFFF',GradientType=0 ) !important;
}

.ace-nav > li.dark-10 > a {
	background-color: transparent !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#19000000', endColorstr='#19000000',GradientType=0 );
}
.ace-nav > li.dark-10 > a:hover, .ace-nav > li.dark-10 > a:focus, .ace-nav > li.open.dark-10 > a {
	background-color: transparent !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33000000', endColorstr='#33000000',GradientType=0 );
}



.navbar .navbar-nav > li  > a:hover,
.navbar .navbar-nav > li  > a:focus,
.navbar .navbar-nav > li.open  > a {
  background-color: transparent !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#19000000', endColorstr='#19000000',GradientType=0 ) !important;
}



.infobox-dark > .badge {
 filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33FFFFFF', endColorstr='#33FFFFFF',GradientType=0 ) !important;
}
.widget-box-overlay {
 filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#55000000', endColorstr='#55000000',GradientType=0 ) !important;
}
.widget-toolbar-light {
 filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#D8FFFFFF', endColorstr='#D8FFFFFF',GradientType=0 ) !important;
}

input[type=checkbox].ace , input[type=radio].ace,
label input[type=checkbox].ace , label input[type=radio].ace,
input[type=checkbox].ace.ace-switch , input[type=radio].ace.ace-switch,
label input[type=checkbox].ace.ace-switch , label input[type=radio].ace.ace-switch 
 {
	position: static !important;
	width: auto !important;
	height: auto !important;
	z-index: auto !important;
}

input[type=checkbox].ace + .lbl, input[type=radio].ace + .lbl,
input[type=checkbox].ace ~ .lbl, input[type=radio].ace ~ .lbl {
	min-height: auto !important;
	min-width: auto !important;
	width: auto !important;
	height: auto !important;
	position: static !important;
}
input[type=checkbox].ace.ace-switch  {
	width: auto !important;
}
input[type=checkbox].ace.ace-switch + .lbl,
input[type=checkbox].ace.ace-switch ~ .lbl {
	margin: 0 !important;
	min-height: auto !important;
}
.checkbox label input.ace[type="checkbox"] + .lbl,
.radio label input.ace[type="radio"] + .lbl,
.checkbox label input.ace[type="checkbox"] ~ .lbl,
.radio label input.ace[type="radio"] ~ .lbl {
	margin-left: auto;
}


label , [class*="input-"] {
	max-width: none !important;
}



.wizard-steps li:first-child:before {
 max-width:100%;
 left:0;
}

.login-layout .widget-box {
 display:none;
 visibility:visible;
 position:static;
}

.login-layout .widget-box.visible {
 display:block;
}
.pricing-box-small:hover {
    left:-1px;
	top:-3px;
}


.ace-thumbnails > li > a > img{
 width:auto !important;
}
.ace-thumbnails > li > :first-child > .text{
 display:none;
}
.ace-thumbnails > li:hover > :first-child  > .text{
 display:block;
}
.ace-thumbnails > li > .tools{
 filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#8C000000', endColorstr='#8C000000', GradientType=0) !important;
}
.ace-thumbnails > li > :first-child > .text{
 filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#8C000000', endColorstr='#8C000000', GradientType=0) !important;
}



.btn.btn-app.btn-light {
  border: 1px solid #D9D9D9;
}
.btn.btn-app.btn-yellow {
  border: 1px solid #FEE188;
}


.widget-box-overlay {
 filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#55000000', endColorstr='#55000000',GradientType=0 ) !important;
}
.ace-file-overlay {
 filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7F000000', endColorstr='#7F000000',GradientType=0 ) !important;
}
.ace-file-overlay  > .ace-icon, .ace-file-overlay  > .fa, .ace-file-overlay  > .glyphicon {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: transparent url('images/loading.gif') no-repeat center; 
}
.ace-file-overlay  > .ace-icon:before, .ace-file-overlay  > .fa:before, .ace-file-overlay  > .glyphicon:before {
  display: none;
}


.grid3 {
 width:31%;
}
.grid4 {
 width:23%;
}

.itemdiv.dialogdiv > .body:before{
 display:none;
}

.fc-event-hori, .fc-event-vert {
 border-width: 0 !important;
}


[class*="tab-color-"] .nav-tabs > li > a > .ace-icon:first-child,
[class*="tab-color-"] .nav-tabs > li > a > .fa:first-child 
 {
 color: #666 !important;
}


.dropdown-preview > .dropdown-menu {
 *width: 180px;
}

/*jquery ui*/
.ui-datepicker , .ui-autocomplete , .ui-menu{
  border:1px solid #CCC;
}
.ui-widget-overlay  {
  filter:alpha(opacity=100) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#44000000', endColorstr='#44000000',GradientType=0 ) !important;
}


.message-content {
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#CCFFFFFF', endColorstr='#CCFFFFFF',GradientType=0 ) !important;
}



/* semi transparent gritter backgrounds */
.gritter-item-wrapper {
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#EA323232', endColorstr='#EA323232',GradientType=0 ) !important; /* IE6-9 */
}
.gritter-item-wrapper.gritter-info {
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#EA315185', endColorstr='#EA315185',GradientType=0 ) !important; /* IE6-9 */
}
.gritter-item-wrapper.gritter-error {
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#EA992812', endColorstr='#EA992812',GradientType=0 ) !important; /* IE6-9 */
}
.gritter-item-wrapper.gritter-success {
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#EA59834B', endColorstr='#EA59834B',GradientType=0 ) !important; /* IE6-9 */
}
.gritter-item-wrapper.gritter-warning {
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#EABE701F', endColorstr='#EABE701F',GradientType=0 ) !important; /* IE6-9 */
}

.gritter-item-wrapper.gritter-light {
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F2F5F5F5', endColorstr='#F2F5F5F5',GradientType=0 ) !important; /* IE6-9 */
}
.gritter-info.gritter-light {
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F2E8F2FF', endColorstr='#F2E8F2FF',GradientType=0 ) !important; /* IE6-9 */
}
.gritter-error.gritter-light {
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F2FFEBEB', endColorstr='#F2FFEBEB',GradientType=0 ) !important; /* IE6-9 */
}
.gritter-success.gritter-light {
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F2EFFAE3', endColorstr='#F2EFFAE3',GradientType=0 ) !important; /* IE6-9 */
}
.gritter-warning.gritter-light {
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F2FCF8E3', endColorstr='#F2FCF8E3',GradientType=0 ) !important; /* IE6-9 */
}




.widget-header .wysiwyg-toolbar .btn-group > .btn , .widget-body .md-header .btn  {
  background:transparent none !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#40FFFFFF', endColorstr='#40FFFFFF',GradientType=0 ) !important;
}
.widget-header .wysiwyg-toolbar .btn-group > .btn.active , .widget-body .md-header .btn-inverse {
  background:transparent none !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#40000000', endColorstr='#40000000',GradientType=0 ) !important;
}
.widget-body .md-header .btn  , .widget-body .md-header .btn-inverse {
	display:none;
}


.scroller-bar , .scroller-active .scroller-bar {
 display: none;	
}
.scroller-active:hover .scroller-bar,
.scroller-active .scroller-bar.active,
.scroller-active .scroller-bar:active,
.nav-wrap:hover + .scroller-active .scroller-bar {
 display: block;
}



.ace-settings-box {
 display: none;
}
.ace-settings-box.open {
 display: block;
}

[data-toggle="buttons"] > .btn > input[type="radio"],
[data-toggle="buttons"] > .btn > input[type="checkbox"] {
  display: none;
}



.aside.aside-hidden.aside-vc .modal-dialog  {
  width: 0;
}
.aside.aside-hidden.aside-hz .modal-dialog  {
  height: 0;
}

 .btn-group > .btn-app:first-child {
	margin-right: 24px;
 }
 .btn-group > .btn-app.dropdown-toggle:first-child {
	margin-right: auto;
 }

 
 .sidebar .submenu.sub-scroll {
  overflow-y: auto;
 }
 
 
.tree .tree-branch > .tree-branch-header:hover {
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1A62A8D1', endColorstr='#1A62A8D1',GradientType=0 );
}
.tree .tree-selected > .tree-branch-header {
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2E62A8D1', endColorstr='#2E62A8D1',GradientType=0 );
}


.tree .tree-item:hover {
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1A62A8D1', endColorstr='#1A62A8D1',GradientType=0 );
}
.tree .tree-selected, .tree-selected:hover  {
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2862A8D1', endColorstr='#2862A8D1',GradientType=0 );
}

}